import mime from 'mime-types';

import IconTxt from '../resources/icon/file-alt-solid.svg';
import IconZip from '../resources/icon/file-archive-solid.svg';
import IconPdf from '../resources/icon/file-pdf-solid.svg';
import IconAlt from '../resources/icon/file-solid.svg';
import IconImg from '../resources/icon/file-image-solid.svg';
import IconDoc from '../resources/icon/file-word-solid.svg';
import IconOgg from '../resources/icon/file-audio-solid.svg';
import IconMp4 from '../resources/icon/file-video-solid.svg';
import IconTag from '../resources/icon/file-code-solid.svg';
import IconCsv from '../resources/icon/file-csv-solid.svg';
import IconXls from '../resources/icon/file-excel-solid.svg';
import IconPpt from '../resources/icon/file-powerpoint-solid.svg';

interface TypeMapping {
	icon: () => JSX.Element;
	types: string[];
}

const extensionArray: TypeMapping[] = [
	{
		icon: IconZip,
		types: [
			'application/zip',
			'application/x-tar',
			'application/java-archive',
			'application/x-rar-compressed',
			'application/x-shar',
			'application/x-bzip',
			'application/x-bzip2',
			'application/x-7z-compressed'
		]
	},
	{
		icon: IconTxt,
		types: [
			'text/plain'
		]
	},
	{
		icon: IconTag,
		types: [
			'application/javascript',
			'text/css',
			'text/html',
			'text/x-c',
			'text/x-fortran',
			'text/x-java-source,java',
			'text/yaml',
			'application/json',
			'application/xml',
			'application/x-sh'
		]
	},
	{
		icon: IconImg,
		types: [
			'image/jpeg',
			'image/bmp',
			'image/png',
			'image/x-png',
			'image/webp',
			'image/svg+xml',
			'image/tiff',
			'image/gif'
		]
	},
	{
		icon: IconCsv,
		types: [
			'text/csv'
		]
	},
	{
		icon: IconOgg,
		types: [
			'audio/midi',
			'audio/mp4',
			'audio/mpeg',
			'audio/ogg',
			'audio/webm',
			'audio/x-ms-wma'
		]
	},
	{
		icon: IconMp4,
		types: [
			'video/3gpp',
			'video/h264',
			'video/mp4',
			'video/mpeg',
			'video/ogg',
			'video/quicktime',
			'video/vnd.uvvu.mp4',
			'video/webm',
			'video/x-flv',
			'video/x-ms-wmv',
			'video/x-msvideo'
		]
	},
	{
		icon: IconPdf,
		types: [
			'application/pdf'
		]
	},
	{
		icon: IconDoc,
		types: [
			'application/rtf',
			'application/msword',
			'application/vnd.ms-word.document.macroenabled.12',
			'application/vnd.ms-word.template.macroenabled.12',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.oasis.opendocument.text-master',
			'application/vnd.oasis.opendocument.text-template',
			'application/vnd.oasis.opendocument.text-web',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
			'application/vnd.sun.xml.writer',
			'application/vnd.sun.xml.writer.global',
			'application/vnd.sun.xml.writer.template'
		]
	},
	{
		icon: IconXls,
		types: [
			'application/vnd.ms-excel',
			'application/vnd.ms-excel.addin.macroenabled.12',
			'application/vnd.ms-excel.sheet.macroenabled.12',
			'application/vnd.ms-excel.template.macroenabled.12',
			'application/vnd.ms-excel.sheet.binary.macroenabled.12',
			'application/vnd.oasis.opendocument.spreadsheet',
			'application/vnd.oasis.opendocument.spreadsheet-template',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
			'application/vnd.sun.xml.calc',
			'application/vnd.sun.xml.calc.template'
		]
	},
	{
		icon: IconPpt,
		types: [
			'application/vnd.ms-powerpoint',
			'application/vnd.ms-powerpoint.addin.macroenabled.12',
			'application/vnd.ms-powerpoint.slide.macroenabled.12',
			'application/vnd.ms-powerpoint.template.macroenabled.12',
			'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
			'application/vnd.ms-powerpoint.presentation.macroenabled.12',
			'application/vnd.oasis.opendocument.presentation',
			'application/vnd.oasis.opendocument.presentation-template',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			'application/vnd.openxmlformats-officedocument.presentationml.slide',
			'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
			'application/vnd.openxmlformats-officedocument.presentationml.template',
			'application/vnd.sun.xml.impress',
			'application/vnd.sun.xml.impress.template'
		]
	}
];

export function getFileImage(file: string): () => JSX.Element {
	const ext: string | false = mime.lookup(file);
	if (!ext) {
		return IconAlt;
	}
	return extensionArray.find((x: TypeMapping): boolean => x.types.includes(ext))?.icon || IconAlt;
}

export function extractExtension(fileName: string): string {
	if (!fileName.includes('.')) {
		return '';
	}
	return fileName.substring(fileName.lastIndexOf('.') + 1);
}

const arr: string[] = ['', 'K', 'M', 'G', 'T', 'P'];

export function countUnit(bytes: number): string {
	const indicator: number = Math.floor(Math.log2(bytes) / 10);
	const result: number = bytes / Math.pow(1024, indicator);
	return `${result.toFixed(2)}${arr[indicator]}B`;
}
