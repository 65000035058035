import Cookies from 'js-cookie';
import {Session} from '../entity/Session';

export class TokenStorage {
	private static _session?: Session;

	static setToken(token: string | undefined, expirationDate?: Date): void {
		if (token) {
			Cookies.set('token', token, {expires: expirationDate});
		} else {
			Cookies.remove('token');
		}
	}

	static getToken(): string | undefined {
		return Cookies.get('token');
	}

	static setSession(session: Session | undefined): void {
		TokenStorage._session = session;
		TokenStorage.setToken(session?.token, new Date(session?.expirationDate || 0));
	}

	static getSession(): Session | undefined {
		return TokenStorage._session;
	}
}
