import React from 'react';
import LoginApp from '../apps/LoginApp';
import IndexApp from '../apps/IndexApp';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

export function App(): JSX.Element {
	return <Router>
		<Switch>
			<Route component={LoginApp} path='/login' exact/>
			<Route component={IndexApp}/>
		</Switch>
	</Router>;
}

export default App;
