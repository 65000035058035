import React from 'react';
import {
	Breadcrumbs,
	Button,
	createStyles,
	Divider,
	Grid,
	Link,
	Paper,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import {User} from '../../entity/User';
import {DraftProject, Project} from '../../entity/Project';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {Link as Anchor} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import parse from 'html-react-parser';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		gridItem: {
			padding: theme.spacing(1)
		}
	})
);

export interface ProjectInfoPageProps {
	project: Project;
	users: User[];
	onProjectUpdate?: (project: Project, draftProject: Partial<DraftProject>) => Promise<void>;
}

export function ProjectInfoPage(props: ProjectInfoPageProps): JSX.Element {
	const [missingProjectName, setMissingProjectName] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string | undefined>(undefined);
	const [description, setDescription] = React.useState<string>('');

	const style: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setDescription(event.target.value);
	}

	function handleEditClick(): void {
		setName(props.project.name);
		setDescription(props.project.description);
	}

	function handleEditCancel(): void {
		setName(undefined);
	}

	function handleEditAccept(): void {
		if (!name) {
			setMissingProjectName(true);
		} else {
			props.onProjectUpdate?.(props.project, {name, description})
				.then((): void => setName(undefined))
				.then((): string | number => enqueueSnackbar(t('project-updated'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}));
		}
	}

	function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setName(event.target.value);
		setMissingProjectName(false);
	}

	return (
		<div>
			<Grid
				container
				direction='column'
				justify='flex-start'
				spacing={2}
			>
				<Grid
					item
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={Anchor} to='/project'>
								<Trans i18nKey='projects'/>
							</Link>
							<Typography color='textPrimary'>{props.project.name}</Typography>
						</Breadcrumbs>
					</Grid>
					{name !== undefined
						? <Grid item>
							<Grid
								container
								direction='row'
								spacing={2}
							>
								<Grid item>
									<Button
										size='small'
										variant='outlined'
										id='button-edit-cancel'
										aria-label='edit'
										onClick={handleEditCancel}
									>
										<Trans i18nKey='cancel'/>
									</Button>
								</Grid>
								<Grid item>
									<Button
										size='small'
										variant='contained'
										disabled={name === ''}
										id='button-edit-accept'
										aria-label='edit'
										onClick={handleEditAccept}
									>
										<Trans i18nKey='ok'/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
						: <Grid item>
							<Button
								size='small'
								variant='outlined'
								id='button-edit'
								aria-label='edit'
								onClick={handleEditClick}
							>
								<Trans i18nKey='edit'/>
							</Button>
						</Grid>}
				</Grid>
				<Grid item>
					<Grid
						component={Paper}
						container
						direction='column'
						justify='flex-start'
					>
						<Grid item className={style.gridItem}>
							{name !== undefined
								? <TextField
									id='name'
									autoComplete='off'
									type='text'
									variant='outlined'
									error={missingProjectName}
									label={t('name')}
									defaultValue={props.project.name}
									onChange={handleNameChange}
									fullWidth
								/>
								: <Typography variant='h5'>{props.project.name}</Typography>}
						</Grid>
						<Divider/>
						<Grid item className={style.gridItem}>
							{name !== undefined
								? <TextField
									id='description'
									type='text'
									multiline
									autoComplete='off'
									variant='outlined'
									rows={5}
									rowsMax={15}
									label={t('description')}
									defaultValue={props.project.description}
									onChange={handleDescriptionChange}
									fullWidth
								/>
								: parse(props.project.description)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
