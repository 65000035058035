import React from 'react';
import {Backdrop, CircularProgress, createStyles, Theme} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		interstitial: {
			color: '#fff',
			zIndex: 10000
		}
	})
);

export interface InterstitialProps {
	open: boolean;
}

export function Interstitial(props: InterstitialProps): JSX.Element {
	const style: ClassNameMap = useStyles();

	return <Backdrop open={props.open} className={style.interstitial}>
		<CircularProgress color='inherit'/>
	</Backdrop>;
}
