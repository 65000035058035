import React from 'react';
import {
	AppBar,
	Avatar,
	createStyles,
	Divider,
	IconButton,
	Link as Anchor,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	Tooltip,
	Typography
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {User} from '../entity/User';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import ProkerLogo from '../../resources/proker-logo.svg';
import {Trans, useTranslation} from 'react-i18next';
import config from '../Config';
import {format} from 'date-fns';
import {getActualLanguage} from '../entity/Language';
import {Settings} from '../types/Interfaces';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		appBar: {
			zIndex: 100,
			backgroundColor: theme.palette.appBar.background
		},
		icon: {
			color: theme.palette.appBar.iconColor
		},
		logo: {
			fill: theme.palette.appBar.foreground,
			height: 42,
			width: 'auto'
		},
		logoLink: {
			lineHeight: 0
		},
		version: {
			color: theme.palette.appBar.foreground,
			lineHeight: '11px',
			marginTop: '7px',
			fontSize: '11px'
		}
	})
);

export interface NavBarProps {
	user: User;
	onLogoutClick?: () => void;
	onProjectsRefresh?: () => void;
	settings: Settings;
}

export function NavBar(props: NavBarProps): JSX.Element {
	const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);

	const style: ClassNameMap = useStyles();
	const {t} = useTranslation();

	function handleMenu(event: React.MouseEvent): void {
		setAnchorEl(event.currentTarget);
	}

	function handleClose(): void {
		setAnchorEl(undefined);
	}

	function generateAvatarLink(): string {
		if (props.user.avatarType === 'gravatar') {
			return `${props.user.avatar}?s=30&d=mp`;
		}
		return props.user.avatar;
	}

	function renderNonInteractiveParenthesis(): JSX.Element {
		return <>({config.branch})</>;
	}

	function renderParenthesis(): JSX.Element {
		if (!config.branch.match(/issue\/#?\d+/)) {
			return renderNonInteractiveParenthesis();
		}
		const id: string = config.branch.replace(/issue\/#?/gi, '');
		if (!id.match(/[0-9]+/)) {
			return renderNonInteractiveParenthesis();
		}
		const address: string = `https://git.recadel.com/proker/proker-webapp/-/issues/${id}`;
		return <>(<Anchor
			target='_blank'
			href={address}
			rel='noopener noreferrer'
			color='primary'
		>{config.branch}</Anchor>)</>;
	}

	function renderVersionText(): JSX.Element {
		if (config.mode === 'production') {
			return <>v{config.version}</>;
		}
		return <>v{config.version}-dev<br/>{renderParenthesis()}</>;
	}

	function renderVersionTypography(): JSX.Element {
		return <Typography variant='caption' className={style.version}>{renderVersionText()}</Typography>;
	}

	function renderVersion(): JSX.Element {
		if (config.mode === 'production') {
			return renderVersionTypography();
		}
		return <Tooltip title={renderTooltipTitle()} arrow>{renderVersionTypography()}</Tooltip>;
	}

	function renderTooltipTitle(): NonNullable<React.ReactNode> {
		const dateTimeFormat: string = `${props.settings.dateFormat}, ${props.settings.timeFormat}`;
		return <div>
			Compiled on {format(new Date(config.compilationDate), dateTimeFormat, {
			locale: getActualLanguage().locale
		})}
		</div>;
	}

	return <AppBar position='sticky' className={style.appBar}>
		<Toolbar>
			<Link to='/' className={style.logoLink}>
				<ProkerLogo className={style.logo}/>
			</Link>
			{renderVersion()}
			<div style={{flexGrow: 1}}/>
			<div>
				<Tooltip title={t<string>('refresh-data')} arrow>
					<IconButton
						id='icon-refresh'
						aria-label='refreshing projects'
						aria-controls='menu-appbar'
						color='inherit'
						onClick={props.onProjectsRefresh}
						className={style.icon}
					>
						<CachedIcon/>
					</IconButton>
				</Tooltip>
				<Tooltip title={t<string>('its-you', {name: props.user.displayName})} arrow>
					<IconButton
						id='icon-open-dropdown'
						aria-label='account of current user'
						aria-controls='menu-appbar'
						aria-haspopup='true'
						color='inherit'
						onClick={handleMenu}
					>
						<Avatar
							variant='circular'
							src={generateAvatarLink()}
							alt='User avatar'
							style={{width: 30, height: 30}}
						/>
					</IconButton>
				</Tooltip>
				<Menu
					id='menu-appbar'
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					anchorEl={anchorEl}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={!!anchorEl}
					onClose={handleClose}
				>
					<MenuItem
						id='menu-item-profile'
						onClick={handleClose}
						component={Link}
						to={`/user/${props.user.id}`}
					>
						<Trans i18nKey='my-profile'/>
					</MenuItem>
					<MenuItem
						id='menu-item-settings'
						onClick={handleClose}
						component={Link}
						to='/settings'
					>
						<Trans i18nKey='settings'/>
					</MenuItem>
					<Divider/>
					<MenuItem
						id='menu-item-logout'
						onClick={props.onLogoutClick}
					>
						<Trans i18nKey='logout'/>
					</MenuItem>
				</Menu>
			</div>
		</Toolbar>
	</AppBar>;
}
