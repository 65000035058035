import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';

export interface ConfirmationDialogProps {
	id?: string;
	className?: string;
	open: boolean;
	negativeText?: string;
	positiveText?: string;
	onCancel?: () => void;
	onConfirm?: () => void;
	title: string;
	content: JSX.Element | string;
}

export function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
	return <Dialog
		id={props.id}
		className={props.className}
		open={props.open}
		onClose={props.onCancel}
		aria-labelledby='deleting-title'
		aria-describedby={props.content && 'confirmation-description'}
	>
		<DialogTitle id='deleting-title'><Trans i18nKey='are-you-sure'/></DialogTitle>
		<DialogContent id='confirmation-description'>{props.content}</DialogContent>
		<DialogActions>
			<Button
				id='button-delete-cancel'
				onClick={props.onCancel}
				variant='outlined'
			>
				{props.negativeText || <Trans i18nKey='cancel'/>}
			</Button>
			<Button
				id='button-delete-confirm'
				onClick={props.onConfirm}
				variant='contained'
			>
				{props.positiveText || <Trans i18nKey='ok'/>}
			</Button>
		</DialogActions>
	</Dialog>;
}
