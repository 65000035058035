export type StatusCode =
	0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6;

export type StatusType =
	'unknown'
	| 'concept'
	| 'defined'
	| 'prototype'
	| 'in-progress'
	| 'archived'
	| 'abandoned'
	| 'maintenance';

export interface ProjectStatus {
	readonly value: StatusCode;
	readonly type: StatusType;
}

export const CONCEPT: ProjectStatus = {value: 0, type: 'concept'};
export const DEFINED: ProjectStatus = {value: 1, type: 'defined'};
export const PROTOTYPE: ProjectStatus = {value: 2, type: 'prototype'};
export const IN_PROGRESS: ProjectStatus = {value: 3, type: 'in-progress'};
export const ARCHIVED: ProjectStatus = {value: 4, type: 'archived'};
export const ABANDONED: ProjectStatus = {value: 5, type: 'abandoned'};
export const MAINTENANCE: ProjectStatus = {value: 6, type: 'maintenance'};

export function getAllStatuses(): ProjectStatus[] {
	return [CONCEPT, DEFINED, PROTOTYPE, IN_PROGRESS, ARCHIVED, ABANDONED, MAINTENANCE];
}

export function findStatus(criterion: StatusCode): ProjectStatus;
export function findStatus(criterion: number): undefined;
export function findStatus(criterion: number): ProjectStatus | undefined {
	return getAllStatuses().find((status: ProjectStatus): boolean => status.value === criterion);
}

export function isStatusCorrect(value: number): value is StatusCode {
	return Number.isInteger(value) && value >= 0 && value <= 6;
}
