import React from 'react';
import {User} from '../../src/entity/User';
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	createStyles,
	Grid,
	Paper,
	Theme,
	Typography
} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {Project} from '../entity/Project';
import {Link} from 'react-router-dom';
import {StatusChip} from '../components/StatusChip';
import {findStatus} from '../entity/ProjectStatus';
import {Trans, useTranslation} from 'react-i18next';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		name: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'block',
			width: '100%'
		},
		description: {
			minHeight: 40,
			height: 40,
			maxHeight: 40,
			overflow: 'hidden',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical'
		},
		status: {
			marginLeft: 'auto !important'
		},
		link: {
			textDecoration: 'none'
		},
		userName: {
			[theme.breakpoints.down('md')]: {
				textAlign: 'center'
			}
		},
		avatarGridItem: {
			maxHeight: 200,
			maxWidth: 200,
			width: 'auto',
			height: 200,
			[theme.breakpoints.down('md')]: {
				margin: 'auto'
			}
		}
	})
);

export interface ProfilePageProps {
	user: User;
	projects: Project[];
}

export function ProfilePage(props: ProfilePageProps): JSX.Element {
	const [count, setCount] = React.useState(12);

	const {t} = useTranslation();
	const styles: ClassNameMap = useStyles();

	function generateAvatarLink(): string {
		if (props.user.avatarType === 'gravatar') {
			return `${props.user.avatar}?s=200&d=mp`;
		}
		return props.user.avatar;
	}

	return <div className={styles.content}>
		<Paper className={styles.content}>
			<Grid
				container
				direction='row'
				justify='space-evenly'
				alignItems='flex-start'
			>
				<Grid
					item
					md={8}
					lg={3}
					xl={2}
					container
					direction='column'
					justify='flex-start'
					spacing={1}
				>
					<Grid item className={styles.avatarGridItem}>
						<Avatar
							variant='circular'
							src={generateAvatarLink()}
							alt='User avatar'
							style={{width: 'auto', height: 'auto'}}
						/>
					</Grid>
					<Grid item className={styles.userName}>
						<Typography variant='h4' component='span'>{props.user.displayName}</Typography>
						<Typography variant='h6' component='span' color='textSecondary'> #{props.user.id}</Typography>
						<Typography style={{marginBottom: 20}}>@{props.user.username}</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction='row'
					justify='center'
					alignContent='center'
					spacing={1}
					sm={12}
					md={12}
					lg={9}
					xl={10}
				>
					<Grid
						item
						container
						direction='row'
						justify='flex-start'
						alignContent='flex-start'
						spacing={1}
					>
						{props.projects.slice(0, count).map((p: Project, index: number): JSX.Element =>
							<Grid
								item
								xl={3}
								lg={4}
								md={4}
								sm={6}
								xs={12}
								key={index}
							>
								<Card variant='outlined'>
									<CardContent>
										<Typography
											className={styles.title}
											color='textSecondary'
											gutterBottom
										>
											#{p.id}
										</Typography>
										<Typography
											variant='h4'
											component='span'
											className={styles.name}
										>
											{p.name}
										</Typography>
										<Typography
											variant='body2'
											component='p'
											className={styles.description}
										>
											{p.description}
										</Typography>
									</CardContent>
									<CardActions>
										<Link to={`/project/${p.id}`} className={styles.link}>
											<Button size='small' variant='outlined'><Trans i18nKey='show'/></Button>
										</Link>
										<StatusChip
											status={findStatus(p.status)}
											className={styles.status}
										/>
									</CardActions>
								</Card>
							</Grid>
						)}
					</Grid>
					{props.projects.length > count && <Grid item>
						<Button variant='contained' onClick={(): void => setCount(count + 12)} id='load-more'>
							{t('load-more-projects')}
						</Button>
					</Grid>}
				</Grid>
			</Grid>
		</Paper>
	</div>;
}

export default ProfilePage;
