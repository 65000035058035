import {de, enUS, pl} from 'date-fns/locale';
import i18next from 'i18next';

export type LanguageId =
	0
	| 1
	| 2;

export type LanguageCode =
	'pl'
	| 'en'
	| 'de';

export interface Language {
	readonly id: LanguageId;
	readonly name: string;
	readonly code: LanguageCode;
	readonly locale: Locale;
}

export const POLISH: Language = {id: 0, name: 'Polski', code: 'pl', locale: pl};
export const ENGLISH: Language = {id: 1, name: 'English', code: 'en', locale: enUS};
export const GERMAN: Language = {id: 2, name: 'Deutsch', code: 'de', locale: de};

export function getAllLanguages(): Language[] {
	return [POLISH, ENGLISH, GERMAN];
}

export function getActualLanguage(): Language {
	return getAllLanguages().find((l: Language): boolean => l.code === i18next.language) || ENGLISH;
}

export function findLanguage(criterion: LanguageId | LanguageCode): Language;
export function findLanguage(criterion: number | string): undefined;
export function findLanguage(criterion: number | string): Language | undefined {
	return getAllLanguages().find((lang: Language): boolean => lang.id === criterion || lang.code === criterion);
}

export function isLanguageCorrect(value: number | string): value is LanguageId | LanguageCode {
	if (Number.isInteger(value) && value >= 0 && value <= 2) {
		return true;
	}
	if (typeof value !== 'string') {
		return false;
	}
	return ['pl', 'en', 'de'].includes(value);
}
