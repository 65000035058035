import React from 'react';
import {getActualLanguage} from '../../entity/Language';
import {createStyles, FormControl, InputLabel, MenuItem, Select, Theme} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {format as formatDate} from 'date-fns';
import {TimeFormat} from '../../types/Interfaces';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		format: {
			width: '125px',
			minWidth: '125px',
			display: 'inline-block'
		}
	})
);

export interface TimeFormatPickerProps {
	name: string;
	onChange?: (format: TimeFormat) => void;
	selectedFormat: TimeFormat;
	id?: string;
}

export function TimeFormatPicker(props: TimeFormatPickerProps): JSX.Element {
	const styles: ClassNameMap = useStyles();
	const sampleDate1: Date = new Date(Date.UTC(2018, 2, 9, 1, 8, 7));
	const sampleDate2: Date = new Date(Date.UTC(2018, 2, 9, 13, 8, 7));

	const timeFormats: TimeFormat[] = [
		'HH:mm:ss',
		'HH:mm',
		'H:mm:ss',
		'H:mm',
		'hh:mm:ss a',
		'hh:mm a',
		'h:mm:ss a',
		'h:mm a'
	];

	// [hH]{1,2}:m{1,2}(:s{1,2})?( a)?

	function renderValue(value: unknown): JSX.Element {
		const format: string = value as string;
		const locale: Locale = getActualLanguage().locale;
		return <span>
			<span className={styles.format}>{formatDate(sampleDate1, format, {locale})}</span>
			<span className={styles.format}>{formatDate(sampleDate2, format, {locale})}</span>
		</span>;
	}

	return <div id={props.id}>
		<FormControl variant='outlined' fullWidth>
			<InputLabel id='time-picker-label'>{props.name}</InputLabel>
			<Select
				labelId='time-picker-label'
				id='time-format-picker'
				value={props.selectedFormat}
				onChange={(event: React.ChangeEvent<{ value: unknown }>): void => props.onChange?.(event.target.value as TimeFormat)}
				label={props.name}
				renderValue={renderValue}
			>
				{timeFormats.map((format: TimeFormat): JSX.Element =>
					<MenuItem value={format} key={format}>
						{renderValue(format)}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	</div>;
}
