import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import {findLanguage, getAllLanguages, isLanguageCorrect, Language, LanguageCode} from '../../entity/Language';

export interface LanguagePickerProps {
	name: string;
	onChange?: (langId: LanguageCode) => void;
	selectedLanguage: LanguageCode;
	id?: string;
}

export function LanguagePicker(props: LanguagePickerProps): JSX.Element {
	function renderValue(value: unknown): JSX.Element {
		const language: number = value as number;
		if (!isLanguageCorrect(language)) {
			return <></>;
		}
		return <span>{findLanguage(language).name}</span>;
	}

	function handleChange(event: React.ChangeEvent<{ value: unknown }>): void {
		const value: string = event.target.value as string;
		if (isLanguageCorrect(value)) {
			props.onChange?.(value);
		}
	}

	return <div id={props.id}>
		<FormControl variant='outlined' fullWidth>
			<InputLabel id='language-label'>{props.name}</InputLabel>
			<Select
				labelId='language-label'
				id='language'
				value={props.selectedLanguage}
				onChange={handleChange}
				label={props.name}
				renderValue={renderValue}
			>
				{getAllLanguages().map((language: Language): JSX.Element =>
					<MenuItem value={language.code} key={language.id}>
						{renderValue(language.id)}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	</div>;
}
