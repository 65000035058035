import React from 'react';
import {Chip, createStyles, Theme, Tooltip} from '@material-ui/core';
import {
	ABANDONED,
	ARCHIVED,
	CONCEPT,
	DEFINED,
	IN_PROGRESS,
	MAINTENANCE,
	ProjectStatus,
	PROTOTYPE
} from '../entity/ProjectStatus';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		chip: {
			width: '140px'
		}
	})
);

export interface StatusChipProps {
	className?: string;
	status: ProjectStatus;
	size?: 'small' | 'medium';
}

export function StatusChip(props: StatusChipProps): JSX.Element {
	const style: ClassNameMap = useStyles();
	const {t} = useTranslation();

	function getStatusStyles(status: ProjectStatus): ClassNameMap {
		switch (status) {
			case CONCEPT:
				return {
					backgroundColor: '#5eb5e0',
					color: 'black'
				};
			case DEFINED:
				return {
					backgroundColor: '#0074D9',
					color: 'white'
				};
			case PROTOTYPE:
				return {
					backgroundColor: '#718C00',
					color: 'white'
				};
			case IN_PROGRESS:
				return {
					backgroundColor: '#006600',
					color: 'white'
				};
			case ABANDONED:
				return {
					backgroundColor: '#222222',
					color: 'white'
				};
			case ARCHIVED:
				return {
					backgroundColor: '#555555',
					color: 'white'
				};
			case MAINTENANCE:
			default:
				return {
					backgroundColor: '#C21F39',
					color: 'white'
				};
		}
	}

	return <Tooltip title={t<string>(`status-description`, {context: props.status.type})} arrow>
		<Chip
			component='span'
			label={t<string>(`status-name`, {context: props.status.type}).toUpperCase()}
			style={getStatusStyles(props.status)}
			className={clsx(style.chip, props.className)}
			size={props.size || 'small'}
		/>
	</Tooltip>;
}
