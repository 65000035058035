import config from '../../Config';
import {EndpointType} from '../ServerManager';

export class AbstractRepository {
	protected readonly where: EndpointType;

	protected constructor(where: EndpointType) {
		this.where = where;
	}

	protected createRoute(data?: string | number): string {
		return `${config.apiHost}/${this.where}${data ? `/${data}` : ''}`;
	}
}
