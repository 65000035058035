import React from 'react';
import clsx from 'clsx';
import {Link, Route, useHistory, useRouteMatch} from 'react-router-dom';
import {DraftProject, Project} from '../entity/Project';
import {
	Button,
	createStyles,
	Divider,
	Drawer,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Theme,
	Toolbar
} from '@material-ui/core';
import {DraftNote, Note} from '../entity/Note';
import {User} from '../entity/User';
import {Attachment, DraftAttachment} from '../entity/Attachment';
import {makeStyles} from '@material-ui/core/styles';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import HomeIcon from '@material-ui/icons/Home';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EventIcon from '@material-ui/icons/Event';
import {match, RouteComponentProps} from 'react-router';
import {ProjectInfoPage} from './singleProject/ProjectInfoPage';
import {AttachmentPage} from './singleProject/AttachmentsPage';
import {NotesPage} from './singleProject/NotesPage';
import {SingleNotePage} from './singleProject/SingleNotePage';
import {History} from 'history';
import {useSnackbar} from 'notistack';
import {StatusChip} from '../components/StatusChip';
import {StatusPicker} from '../components/picker/StatusPicker';
import {UserPicker} from '../components/picker/UserPicker';
import {findStatus, StatusCode} from '../entity/ProjectStatus';
import {AxiosRequestConfig} from 'axios';
import Cookies from 'js-cookie';
import {Trans, useTranslation} from 'react-i18next';
import {getActualLanguage} from '../entity/Language';
import {format} from 'date-fns';
import {Settings} from '../types/Interfaces';

const drawerLeftWidth: number = 180;
const drawerRightWidth: number = 260;

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			display: 'flex'
		},
		drawer: {
			flexShrink: 0,
			whiteSpace: 'nowrap',
			zIndex: 50
		},
		drawerLeft: {
			width: drawerLeftWidth
		},
		drawerRight: {
			width: drawerRightWidth
		},
		drawerOpen: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		drawerLeftOpen: {
			width: drawerLeftWidth
		},
		drawerRightOpen: {
			width: drawerRightWidth
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: 'hidden',
			width: theme.spacing(4) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(7) + 1
			}
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		drawerIcon: {
			minWidth: 40
		},
		drawerRightElement: {
			height: '76px'
		},
		userLink: {
			textDecoration: 'none',
			color: 'inherit',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	})
);

export interface SingleProjectPageProps {
	project: Project;
	user: User;
	users: User[];
	notes: Note[];
	onProjectUpdate?: (project: Project, draftProject: Partial<DraftProject>) => Promise<void>;
	onNoteCreate?: (draftNote: DraftNote, projectId: number) => Promise<void>;
	onNoteUpdate?: (note: Note, draftNote: DraftNote) => Promise<void>;
	onNoteDelete?: (note: Note) => Promise<void>;
	attachments: Attachment[];
	onAttachmentUpload?: (formData: FormData, config: AxiosRequestConfig) => Promise<void>;
	onAttachmentUpdate?: (attachment: Attachment, draftAttachment: DraftAttachment) => Promise<void>;
	onAttachmentDelete?: (attachment: Attachment) => Promise<void>;
	settings: Settings;
}

export function SingleProjectPage(props: SingleProjectPageProps): JSX.Element {
	const [openLeft, setOpenLeft] = React.useState<boolean>(false);
	const [openRight, setOpenRight] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<number>(0);
	const [status, setStatus] = React.useState<StatusCode>(props.project.status);

	const style: ClassNameMap = useStyles();
	const addr: match = useRouteMatch();
	const {enqueueSnackbar} = useSnackbar();
	const history: History = useHistory();
	const {t} = useTranslation();

	const dateTimeFormat: string = `${props.settings.dateFormat || 'do MMM yy'}, ${props.settings.timeFormat || 'HH:mm:ss'}`;

	React.useEffect((): void => {
		setOpenLeft(Cookies.get('openLeft') === 'true');
		setOpenRight(Cookies.get('openRight') === 'true');
	}, []);

	function handleNoteConfirm(draftNote: DraftNote, id?: number): void {
		const note: Note | undefined = props.notes.find((n: Note): boolean => n.id === id);
		if (note && id) {
			props.onNoteUpdate?.(note, draftNote)
				.then((): string | number => enqueueSnackbar(t('note-updated'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}))
				.finally((): void => history.go(-1));
		} else {
			props.onNoteCreate?.(draftNote, props.project.id)
				.then((): string | number => enqueueSnackbar(t('note-created'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}))
				.finally((): void => history.go(-1));
		}
	}

	function handleUpdateClick(): void {
		props.onProjectUpdate?.(props.project, {user, status})
			.then((): string | number => enqueueSnackbar(t('project-updated'), {variant: 'success'}))
			.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}))
			.finally((): void => setUser(0));
	}

	function openLeftDrawer(): void {
		setOpenLeft(!openLeft);
		Cookies.set('openLeft', `${!openLeft}`);
	}

	function openRightDrawer(): void {
		setUser(0);
		setOpenRight(!openRight);
		Cookies.set('openRight', `${!openRight}`);
	}

	function generateAuthor(): JSX.Element {
		const author: User | undefined = props.users.find((u: User): boolean => u.id === props.project.user);
		return <Link to={`/user/${author?.id}`} className={style.userLink}>
			{author?.displayName}
		</Link>;
	}

	return <div className={style.root}>
		<Drawer
			variant='permanent'
			className={clsx(style.drawer, {
				[style.drawerOpen + ' ' + style.drawerLeftOpen]: openLeft,
				[style.drawerClose]: !openLeft
			})}
			classes={{
				paper: clsx({
					[style.drawerOpen + ' ' + style.drawerLeftOpen]: openLeft,
					[style.drawerClose]: !openLeft
				})
			}}
		>
			<Toolbar/>
			<div>
				<List dense>
					<ListItem button component={Link} to={addr.url}>
						<ListItemIcon className={style.drawerIcon}><HomeIcon/></ListItemIcon>
						<ListItemText primary={t('project-info')}/>
					</ListItem>
					<ListItem button component={Link} to={`${addr.url}/attachment`}>
						<ListItemIcon className={style.drawerIcon}><AttachmentIcon/></ListItemIcon>
						<ListItemText primary={t('attachments')}/>
					</ListItem>
					<ListItem button component={Link} to={`${addr.url}/note`}>
						<ListItemIcon className={style.drawerIcon}><AssignmentIcon/></ListItemIcon>
						<ListItemText primary={t('notes')}/>
					</ListItem>
					<ListItem button disabled>
						<ListItemIcon className={style.drawerIcon}><SettingsIcon/></ListItemIcon>
						<ListItemText primary={t('settings')}/>
					</ListItem>
					<Divider/>
					<ListItem button onClick={openLeftDrawer} id='collapse-left'>
						<ListItemIcon className={style.drawerIcon}>
							{openLeft ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
						</ListItemIcon>
						<ListItemText primary={t('collapse')}/>
					</ListItem>
				</List>
			</div>
		</Drawer>
		<main className={style.content}>
			<Route path={addr.url} exact>
				<ProjectInfoPage
					project={props.project}
					users={props.users}
					onProjectUpdate={props.onProjectUpdate}
				/>
			</Route>
			<Route path={`${addr.url}/attachment`} exact>
				<AttachmentPage
					project={props.project}
					attachments={props.attachments}
					onAttachmentUpload={props.onAttachmentUpload}
					onAttachmentUpdate={props.onAttachmentUpdate}
					onAttachmentDelete={props.onAttachmentDelete}
					users={props.users}
					user={props.user}
					dateTimeFormat={dateTimeFormat}
					extensionChangeConfirmation={props.settings.extensionChangeConfirmation}
				/>
			</Route>
			<Route path={`${addr.url}/note`} exact>
				<NotesPage
					project={props.project}
					notes={props.notes}
					onNoteDelete={props.onNoteDelete}
					users={props.users}
					dateTimeFormat={dateTimeFormat}
				/>
			</Route>
			<Route path={`${addr.url}/note/create`} exact>
				<SingleNotePage
					user={props.user}
					project={props.project}
					onConfirm={handleNoteConfirm}
					onCancel={(): void => history.go(-1)}
				/>
			</Route>
			<Route
				id='note'
				path={`${addr.url}/note/:id`}
				exact
				render={(componentProps: RouteComponentProps<{ id: string }>): JSX.Element | undefined => {
					if (!props.project) {
						return undefined;
					}
					const noteId: number = +componentProps.match.params.id;
					if (isNaN(noteId) || noteId <= 0) {
						return undefined;
					}
					return <SingleNotePage
						user={props.user}
						project={props.project}
						note={props.notes.find((note: Note): boolean => note.id === noteId)}
						onConfirm={(draftNote: DraftNote): void => handleNoteConfirm(draftNote, noteId)}
						onCancel={(): void => history.go(-1)}
					/>;
				}}
			/>
		</main>
		<Drawer
			anchor='right'
			variant='permanent'
			className={clsx(style.drawer, {
				[clsx(style.drawerOpen, style.drawerRightOpen)]: openRight,
				[style.drawerClose]: !openRight
			})}
			classes={{
				paper: clsx({
					[clsx(style.drawerOpen, style.drawerRightOpen)]: openRight,
					[style.drawerClose]: !openRight
				})
			}}
		>
			<Toolbar/>
			<div>
				<List>
					<ListItem>
						<ListItemText>
							<Grid
								container
								direction='row'
								justify='flex-end'
								style={{
									minWidth: '200px'
								}}
								spacing={1}
							>
								{user
									? <>
										<Grid item>
											<Button
												id='metadata-cancel'
												size='small'
												variant='outlined'
												onClick={(): void => setUser(0)}
											>
												<Trans i18nKey='cancel'/>
											</Button>
										</Grid>
										<Grid item>
											<Button
												id='metadata-ok'
												size='small'
												variant='contained'
												onClick={handleUpdateClick}
											>
												<Trans i18nKey='ok'/>
											</Button>
										</Grid>
									</>
									: <Grid item>
										<Button
											size='small'
											id='metadata-edit'
											variant='outlined'
											onClick={(): void => setUser(props.project.user)}
										>
											<Trans i18nKey='edit'/>
										</Button>
									</Grid>
								}
							</Grid>
						</ListItemText>
					</ListItem>
					<ListItem className={style.drawerRightElement}>
						<ListItemIcon className={style.drawerIcon}><EventIcon/></ListItemIcon>
						<ListItemText
							primary={t('date-created')}
							secondary={format(new Date(props.project.date), dateTimeFormat, {
								locale: getActualLanguage().locale
							})}
						/>
					</ListItem>
					<ListItem className={style.drawerRightElement}>
						<ListItemIcon className={style.drawerIcon}><AssignmentIndIcon/></ListItemIcon>
						{user
							? <UserPicker
								name={t('author')}
								users={props.users}
								selectedUser={user}
								onChange={setUser}
								id='users'
								viewSize='small'
							/>
							: <ListItemText primary={t('author')} secondary={generateAuthor()}/>}
					</ListItem>
					<ListItem className={style.drawerRightElement}>
						<ListItemIcon className={style.drawerIcon}><BeenhereIcon/></ListItemIcon>
						{user
							? <StatusPicker
								name={t('status')}
								status={status}
								viewSize='small'
								onChange={setStatus}
								id='status'
							/>
							: <ListItemText
								primary='Status'
								secondary={<StatusChip status={findStatus(props.project.status)}/>}
							/>}
					</ListItem>
					<Divider
						style={{
							marginTop: '5px'
						}}
					/>
					<ListItem
						button
						onClick={openRightDrawer}
						id='collapse-right'
					>
						<ListItemIcon className={style.drawerIcon}>
							{openRight ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
						</ListItemIcon>
						<ListItemText primary={t('collapse')}/>
					</ListItem>
				</List>
			</div>
		</Drawer>
	</div>;
}

export default SingleProjectPage;
