import React from 'react';
import {Button, createStyles, Grid, TextField, Theme} from '@material-ui/core';
import ProkerLogo from '../../resources/proker-logo.svg';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {AxiosError} from 'axios';
import {useSnackbar} from 'notistack';
import {Trans, useTranslation} from 'react-i18next';
import {Interstitial} from './Interstitial';
import {LanguagePicker} from './picker/LanguagePicker';
import {LanguageCode} from '../entity/Language';
import i18next from 'i18next';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		logotype: {
			width: '80%',
			height: 'auto',
			marginBottom: theme.spacing(3)
		},
		form: {
			flexWrap: 'nowrap',
			padding: theme.spacing(3),
			alignSelf: 'center',
			width: '100%',
			margin: 0
		}
	})
);

export interface LoginFormProps {
	readonly id?: string;
	readonly onSubmit: (login: string, password: string) => Promise<void>;
}

export function LoginForm(props: LoginFormProps): JSX.Element {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [login, setLogin] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [language, setLanguage] = React.useState<LanguageCode>('en');

	const styles: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleFormSubmit(event: React.MouseEvent<HTMLFormElement>): void {
		event.preventDefault();
		setLoading(true);
		props.onSubmit(login, password).catch((response: AxiosError): void => {
			setLoading(false);
			if ([401, 403].includes(response.response?.status || 0)) {
				enqueueSnackbar(t('invalid-credentials'), {variant: 'error'});
			} else {
				enqueueSnackbar(t('unknown-error'), {variant: 'error'});
			}
		});
	}

	function handleLoginChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		setLogin(event.currentTarget.value);
	}

	function handlePasswordChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		setPassword(event.currentTarget.value);
	}

	function handleLanguageChange(lang: LanguageCode): void {
		i18next.changeLanguage(lang)
			.then((): void => setLanguage(lang));
	}

	return <form id={props.id} className={styles.form} onSubmit={handleFormSubmit}>
		<Interstitial open={loading}/>
		<Grid
			className={styles.form}
			container
			direction='column'
			justify='center'
			alignItems='stretch'
			spacing={3}
		>
			<Grid item container justify='center'>
				<ProkerLogo className={styles.logotype}/>
			</Grid>
			<Grid item>
				<TextField
					id='login-email'
					fullWidth
					label={t('username-or-email')}
					variant='outlined'
					name='login'
					onChange={handleLoginChanged}
				/>
			</Grid>
			<Grid item>
				<TextField
					id='login-password'
					fullWidth
					type='password'
					label={t('password')}
					variant='outlined'
					name='password'
					onChange={handlePasswordChanged}
				/>
			</Grid>
			<Grid item container justify='center' direction='row' spacing={4}>
				<Grid item>
					<Button
						fullWidth
						variant='contained'
						type='submit'
						color='primary'
						size='large'
					>
						<Trans i18nKey='sign-in'/>
					</Button>
				</Grid>
			</Grid>
			<Grid item>
				<LanguagePicker
					id='language-picker'
					name='Language'
					selectedLanguage={language}
					onChange={handleLanguageChange}
				/>
			</Grid>
		</Grid>
	</form>;
}
