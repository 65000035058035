import {EntityRepository} from './EntityRepository';
import {AxiosRequestConfig} from 'axios';
import {DraftSession, Session} from '../../entity/Session';
import {ServerMediator} from '../ServerMediator';

export class SessionsRepository extends EntityRepository<number, Session, DraftSession> {
	constructor() {
		super('sessions');
	}

	getAll(axiosConfig?: AxiosRequestConfig): Promise<Session[]> {
		return ServerMediator.requestWithToken<Session[]>('GET', this.createRoute(), axiosConfig);
	}

	get(id: number, axiosConfig?: AxiosRequestConfig): Promise<Session> {
		return ServerMediator.requestWithToken<Session>('GET', this.createRoute(id), axiosConfig);
	}

	getCurrent(axiosConfig?: AxiosRequestConfig): Promise<Session> {
		return ServerMediator.requestWithToken<Session>('GET', this.createRoute('current'), axiosConfig);
	}

	create(de: DraftSession, axiosConfig?: AxiosRequestConfig, project?: number): Promise<Session> {
		return ServerMediator.request<Session>('POST', this.createRoute('create'), axiosConfig, de);
	}

	refresh(axiosConfig?: AxiosRequestConfig): Promise<Session> {
		return ServerMediator.requestWithToken<Session>('POST', this.createRoute('refresh'), axiosConfig);
	}

	update(id: number, de: Partial<DraftSession>, axiosConfig?: AxiosRequestConfig): Promise<void> {
		throw new Error('Not implemented.');
	}

	removeAll(axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute(), axiosConfig);
	}

	remove(e: Session, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute(e.id), axiosConfig);
	}

	removeCurrent(axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute('current'), axiosConfig);
	}
}
