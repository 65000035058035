import React from 'react';
import {DraftUser, User} from '../entity/User';
import {createStyles, Drawer, List, ListItem, ListItemIcon, ListItemText, Theme, Toolbar} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SecurityIcon from '@material-ui/icons/Security';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import TuneIcon from '@material-ui/icons/Tune';
import {makeStyles} from '@material-ui/core/styles';
import {UserSettingsPage} from './settings/UserSettingsPage';
import {Link, Redirect, Route, useRouteMatch} from 'react-router-dom';
import {SecuritySettingsPage} from './settings/SecuritySettingsPage';
import {PreferencesSettingsPage} from './settings/PreferencesSettingsPage';
import {match} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Settings} from '../types/Interfaces';
import {AdvancedSettingsPage} from './settings/AdvancedSettingsPage';

const drawerWidth: number = 240;

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		root: {
			display: 'flex'
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap'
		},
		drawerPaper: {
			width: drawerWidth,
			zIndex: 50
		},
		drawerContainer: {
			overflow: 'auto'
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(5)
		},
		drawerIcon: {
			minWidth: 39
		}
	})
);

export interface SettingsPageProps {
	user: User;
	onUserUpdate?: (user: User, draftUser: Partial<DraftUser>) => Promise<void>;
	onCredentialsUpdate?: (user: User, oldPassword: string, newPassword: string) => Promise<void>;
	settings: Settings;
	onSettingsUpdate?: (settings: Partial<Settings>) => void;
}

export function SettingsPage(props: SettingsPageProps): JSX.Element {
	const styles: ClassNameMap = useStyles();
	const addr: match = useRouteMatch();
	const {t} = useTranslation();

	return <div className={styles.root}>
		<Drawer
			className={styles.drawer}
			variant='permanent'
			classes={{
				paper: styles.drawerPaper
			}}
			anchor='left'
		>
			<Toolbar/>
			<List dense>
				<ListItem button component={Link} to={`${addr.path}/user`}>
					<ListItemIcon className={styles.drawerIcon}><AccountBoxIcon/></ListItemIcon>
					<ListItemText primary={t('user-settings')}/>
				</ListItem>
				<ListItem button component={Link} to={`${addr.path}/security`}>
					<ListItemIcon className={styles.drawerIcon}><SecurityIcon/></ListItemIcon>
					<ListItemText primary={t('security')}/>
				</ListItem>
				<ListItem button component={Link} to={`${addr.path}/preferences`}>
					<ListItemIcon className={styles.drawerIcon}><FormatPaintIcon/></ListItemIcon>
					<ListItemText primary={t('preferences')}/>
				</ListItem>
				<ListItem button component={Link} to={`${addr.path}/advanced`}>
					<ListItemIcon className={styles.drawerIcon}><TuneIcon/></ListItemIcon>
					<ListItemText primary={t('advanced')}/>
				</ListItem>
			</List>
		</Drawer>
		<main className={styles.content}>
			<Route path={`${addr.path}/user`}>
				<UserSettingsPage
					user={props.user}
					onUserUpdate={props.onUserUpdate}
				/>
			</Route>
			<Route path={`${addr.path}/security`}>
				<SecuritySettingsPage
					user={props.user}
					onCredentialsUpdate={props.onCredentialsUpdate}
				/>
			</Route>
			<Route path={`${addr.path}/preferences`}>
				<PreferencesSettingsPage
					preferences={props.settings}
					onPreferencesUpdate={props.onSettingsUpdate}
				/>
			</Route>
			<Route path={`${addr.path}/advanced`}>
				<AdvancedSettingsPage
					advancedSettings={props.settings}
					onAdvancedSettingsUpdate={props.onSettingsUpdate}
				/>
			</Route>
			<Route path='/settings' exact>
				<Redirect to='/settings/user'/>
			</Route>
		</main>
	</div>;
}
