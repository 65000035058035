import React from 'react';
import {Note} from '../../entity/Note';
import {
	Breadcrumbs,
	Button,
	createStyles,
	Grid,
	IconButton,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Tooltip,
	Typography
} from '@material-ui/core';
import {Project} from '../../entity/Project';
import {User} from '../../entity/User';
import {ConfirmationDialog} from '../../components/ConfirmationDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {Link as Anchor, useRouteMatch} from 'react-router-dom';
import {match} from 'react-router';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {Trans, useTranslation} from 'react-i18next';
import {getActualLanguage} from '../../entity/Language';
import {format} from 'date-fns';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		columnName: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			maxWidth: '500px'
		},
		columnDate: {
			width: '250px',
			maxWidth: '250px'
		},
		columnAuthor: {
			width: '150px',
			maxWidth: '150px'
		},
		columnButtons: {
			width: '100px',
			maxWidth: '100px'
		},
		userLink: {
			textDecoration: 'none',
			color: 'inherit',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	})
);

export interface NotesPageProps {
	project: Project;
	notes: Note[];
	onNoteDelete?: (note: Note) => Promise<void>;
	users: User[];
	dateTimeFormat: string;
}

export function NotesPage(props: NotesPageProps): JSX.Element {
	const [deleting, setDeleting] = React.useState<Note | undefined>(undefined);

	const addr: match = useRouteMatch();
	const style: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleDeleteCancel(): void {
		setDeleting(undefined);
	}

	function handleDeleteClick(note: Note): void {
		setDeleting(note);
	}

	function handleNoteDeleted(): void {
		if (deleting) {
			setDeleting(undefined);
			props.onNoteDelete?.(deleting)
				.then((): string | number => enqueueSnackbar(t('note-deleted'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}));
		}
	}

	function generateAuthor(note: Note): JSX.Element {
		const found: User | undefined = props.users.find((u: User): boolean => u.id === note.user);
		return <Anchor to={`/user/${found?.id}`} className={style.userLink}>
			{found?.displayName}
		</Anchor>;
	}

	return (
		<>
			<Grid
				container
				direction='column'
				justify='flex-start'
				spacing={1}
			>
				<Grid
					item
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={Anchor} to='/project'>
								<Trans i18nKey='projects'/>
							</Link>
							<Link component={Anchor} to={`/project/${props.project.id}`}>
								{props.project.name}
							</Link>
							<Typography color='textPrimary'><Trans i18nKey='notes'/></Typography>
						</Breadcrumbs>
					</Grid>
					<Grid item>
						<Link component={Anchor} to={`${addr.path}/create`} style={{textDecoration: 'none'}}>
							<Button variant='outlined' size='small'>
								<Trans i18nKey='create'/>
							</Button>
						</Link>
					</Grid>
				</Grid>
				<Grid item>
					<TableContainer component={Paper}>
						<Table aria-label='attachments' size='small'>
							<TableHead>
								<TableRow>
									<TableCell className={style.columnName}><Trans i18nKey='name'/></TableCell>
									<TableCell className={style.columnDate}><Trans i18nKey='date'/></TableCell>
									<TableCell className={style.columnAuthor}><Trans i18nKey='author'/></TableCell>
									<TableCell className={style.columnButtons}/>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.notes.map((note: Note, index: number): JSX.Element =>
									<TableRow key={index}>
										<TableCell className={style.columnName} component='th' scope='row'>
											{note.title}
										</TableCell>
										<TableCell className={style.columnDate}>
											{format(new Date(note.date), props.dateTimeFormat, {
												locale: getActualLanguage().locale
											})}
										</TableCell>
										<TableCell className={style.columnAuthor}>
											{generateAuthor(note)}
										</TableCell>
										<TableCell className={style.columnButtons} align='right'>
											<Grid
												container
												direction='row'
												justify='space-between'
												alignItems='center'
											>
												<Grid item>
													<Anchor to={`${addr.path}/${note.id}`}>
														<Tooltip title={t<string>('view-note')} arrow>
															<IconButton size='small'>
																<VisibilityOutlinedIcon/>
															</IconButton>
														</Tooltip>
													</Anchor>
												</Grid>
												<Grid item>
													<Tooltip title={t<string>('delete')} arrow>
														<IconButton
															id='note-delete'
															size='small'
															onClick={(): void => handleDeleteClick(note)}
														>
															<DeleteIcon/>
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<ConfirmationDialog
				open={deleting !== undefined}
				onCancel={handleDeleteCancel}
				onConfirm={handleNoteDeleted}
				title={t('are-you-sure-deleting')}
				content={<ul>
					<li>{deleting?.title}</li>
				</ul>}
				positiveText={t('delete')}
			/>
		</>
	);
}
