import React from 'react';
import {Button, Tooltip} from '@material-ui/core';
import {countUnit} from '../Utils';
import {ServerManager} from '../server/ServerManager';
import {Trans} from 'react-i18next';

export interface FilePickerProps {
	onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

export function FilePicker(props: FilePickerProps): JSX.Element {
	return <div>
		<input
			id='input-file-picker'
			type='file'
			onChange={props.onFileChange}
			disabled={props.disabled}
			multiple
			style={{display: 'none'}}
		/>
		<label htmlFor='input-file-picker'>
			<Tooltip title={`Max single file size is ${countUnit(ServerManager.instance.getMaxUpload())}.`} arrow>
				<Button variant='outlined' component='span' disabled={props.disabled} size='small'>
					<Trans i18nKey='upload'/>
				</Button>
			</Tooltip>
		</label>
	</div>;
}
