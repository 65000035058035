import {AxiosRequestConfig} from 'axios';
import {AbstractRepository} from './AbstractRepository';
import {EndpointType} from '../ServerManager';

export abstract class EntityRepository<ID, E, DE, C = DE> extends AbstractRepository {
	protected constructor(where: EndpointType) {
		super(where);
	}

	abstract getAll(axiosConfig?: AxiosRequestConfig): Promise<E[]>;

	abstract get(id: ID, axiosConfig?: AxiosRequestConfig): Promise<E>;

	abstract create(de: C, axiosConfig?: AxiosRequestConfig, project?: number): Promise<ID | E>;

	abstract update(id: ID, de: Partial<DE>, axiosConfig?: AxiosRequestConfig): Promise<void>;

	abstract remove(e: E, axiosConfig?: AxiosRequestConfig): Promise<void>;
}
