import React from 'react';
import {getActualLanguage} from '../../entity/Language';
import {createStyles, FormControl, InputLabel, MenuItem, Select, Theme} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {format as formatDate} from 'date-fns';
import {DateFormat} from '../../types/Interfaces';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		format: {
			width: '125px',
			minWidth: '125px',
			display: 'inline-block'
		}
	})
);

export interface DateFormatPickerProps {
	name: string;
	onChange?: (format: DateFormat) => void;
	selectedFormat: DateFormat;
	id?: string;
}

export function DateFormatPicker(props: DateFormatPickerProps): JSX.Element {
	const styles: ClassNameMap = useStyles();
	const sampleDate1: Date = new Date(Date.UTC(2018, 2, 9, 1, 8, 7));

	const dateFormats: DateFormat[] = [
		'do MMMM y',
		'do MMMM yy',
		'do MMM y',
		'do MMM yy',
		'dd.MM.y',
		'dd.MM.yy',
		'LLLL dd, y',
		'LLLL dd, yy',
		'LLLL d, y',
		'LLLL d, yy',
		'LLL dd, y',
		'LLL dd, yy',
		'LLL d, y',
		'LLL d, yy',
		'MM/dd/y',
		'MM/dd/yy',
		'MM/d/y',
		'MM/d/yy',
		'M/dd/y',
		'M/dd/yy',
		'M/d/y',
		'M/d/yy'
	];

	// [doMyL ,.\/]{5,11}

	function renderValue(value: unknown): JSX.Element {
		const format: string = value as string;
		const locale: Locale = getActualLanguage().locale;
		return <span>
			<span className={styles.format}>{formatDate(sampleDate1, format, {locale})}</span>
		</span>;
	}

	return <div id={props.id}>
		<FormControl variant='outlined' fullWidth>
			<InputLabel id='date-format-label'>{props.name}</InputLabel>
			<Select
				labelId='date-format-label'
				id='date-format-picker'
				value={props.selectedFormat}
				onChange={(event: React.ChangeEvent<{ value: unknown }>): void => props.onChange?.(event.target.value as DateFormat)}
				label={props.name}
				renderValue={renderValue}
			>
				{dateFormats.map((format: DateFormat): JSX.Element =>
					<MenuItem value={format} key={format}>
						{renderValue(format)}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	</div>;
}
