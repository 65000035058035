import Axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {ProkerResponse, ServerManager} from './ServerManager';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import {Session} from '../entity/Session';
import {TokenStorage} from './TokenStorage';

export class ServerMediator {
	static obtainToken(login: string, password: string): Promise<Session> {
		return ServerManager.instance.sessionsRepository.create({login, password});
	}

	static refreshSession(): Promise<void> {
		return ServerManager.instance.sessionsRepository.refresh().then(TokenStorage.setSession);
	}

	static getCurrentSession(): Promise<void> {
		return ServerManager.instance.sessionsRepository.getCurrent().then(TokenStorage.setSession);
	}

	// tslint:disable-next-line:no-any
	static request<E = void>(method: Method, url: string, axiosConfig?: AxiosRequestConfig, data?: any): Promise<E> {
		return Axios(_.merge({method, url, data}, axiosConfig))
			.then((response: AxiosResponse<ProkerResponse<E>>): E => response.data?.data);
	}

	// tslint:disable-next-line:no-any
	static requestWithToken<E = void>(method: Method, url: string, axiosConfig?: AxiosRequestConfig, data?: any): Promise<E> {
		return this.request<E>(method, url, _.merge(axiosConfig, {
			headers: {
				'X-Auth-Token': TokenStorage.getToken()
			}
		}), data);
	}

	static authorizeUrl(url: string): string {
		const localSession: Session | undefined = TokenStorage.getSession();
		if (!localSession) {
			return url;
		}
		const sha: string = CryptoJS.SHA1(`${localSession.token}|${url}`).toString();
		return `${url}?sig=${sha}&ts=${new Date(localSession.creationDate).getTime() / 1000}`;
	}
}
