import {DraftUser, User} from '../../entity/User';
import {EntityRepository} from './EntityRepository';
import {ServerMediator} from '../ServerMediator';
import {AxiosRequestConfig} from 'axios';
import {Settings} from '../../types/Interfaces';

export class UsersRepository extends EntityRepository<number, User, DraftUser> {
	constructor() {
		super('users');
	}

	getAll(axiosConfig?: AxiosRequestConfig): Promise<User[]> {
		return ServerMediator.requestWithToken<User[]>('GET', this.createRoute(), axiosConfig);
	}

	get(id: number, axiosConfig?: AxiosRequestConfig): Promise<User> {
		return ServerMediator.requestWithToken<User>('GET', this.createRoute(id), axiosConfig);
	}

	create(draftUser: DraftUser): Promise<number> {
		throw new Error('Not implemented yet');
	}

	update(userId: number, user: Partial<DraftUser>, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('PATCH', this.createRoute(userId), axiosConfig, user);
	}

	remove(user: User): Promise<void> {
		throw new Error('Not implemented yet');
	}

	getMe(axiosConfig?: AxiosRequestConfig): Promise<User> {
		return ServerMediator.requestWithToken<User>('GET', this.createRoute('me'), axiosConfig);
	}

	getSettings(axiosConfig?: AxiosRequestConfig): Promise<Settings> {
		return ServerMediator.requestWithToken<Settings>('GET', this.createRoute('me/settings'), axiosConfig);
	}

	setSettings(preferences: Partial<Settings>, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken<void>('PATCH', this.createRoute('me/settings'), axiosConfig, preferences);
	}

	changePassword(userId: number, oldPassword: string, password: string, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('PATCH', this.createRoute(userId), axiosConfig, {oldPassword, password});
	}
}
