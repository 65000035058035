import React from 'react';
import {createStyles, FormControl, InputLabel, MenuItem, Select, Theme} from '@material-ui/core';
import {findStatus, getAllStatuses, isStatusCorrect, ProjectStatus, StatusCode} from '../../entity/ProjectStatus';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		statusName: {
			width: '125px',
			minWidth: '125px',
			display: 'inline-block'
		},
		statusDescription: {
			color: 'gray'
		},
		statusNameSmall: {
			fontSize: '14px'
		}
	})
);

export interface StatusPickerProps {
	name: string;
	className?: string;
	status: StatusCode;
	onChange?: (status: StatusCode) => void;
	id?: string;
	viewSize?: 'normal' | 'small';
}

export function StatusPicker(props: StatusPickerProps): JSX.Element {
	const style: ClassNameMap = useStyles();
	const {t} = useTranslation();

	function renderSmallValue(value: unknown): JSX.Element {
		const status: number = value as number;
		if (!isStatusCorrect(status)) {
			return <></>;
		}
		const projectStatus: ProjectStatus = findStatus(status);
		return <span>
			<span className={clsx(style.statusName, {[style.statusNameSmall]: props.viewSize === 'small'})}>
				{t('status-name', {context: projectStatus.type}).toUpperCase()}
			</span>
		</span>;
	}

	function renderValue(value: unknown): JSX.Element {
		const status: number = value as number;
		if (!isStatusCorrect(status)) {
			return <></>;
		}
		const projectStatus: ProjectStatus = findStatus(status);
		return <span>
			<span className={style.statusName}>{t('status-name', {context: projectStatus.type}).toUpperCase()}</span>
			<span className={style.statusDescription}>{t('status-description', {context: projectStatus.type})}</span>
		</span>;
	}

	function handleChange(event: React.ChangeEvent<{ value: unknown }>): void {
		const value: number = event.target.value as number;
		if (isStatusCorrect(value)) {
			props.onChange?.(value);
		}
	}

	return (
		<div className={props.className} id={props.id}>
			<FormControl variant='outlined' fullWidth>
				<InputLabel id='status-label'>{props.name}</InputLabel>
				<Select
					id='status-select'
					labelId='status-label'
					value={props.status}
					onChange={handleChange}
					label={props.name}
					SelectDisplayProps={props.viewSize === 'normal' ? undefined : {
						style: {
							paddingTop: '14px',
							paddingBottom: '14px'
						}
					}}
					renderValue={props.viewSize === 'small' ? renderSmallValue : renderValue}
				>
					{getAllStatuses().map((status: ProjectStatus): JSX.Element =>
						<MenuItem value={status.value} key={status.value}>
							{renderValue(status.value)}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
}
