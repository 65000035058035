import React from 'react';
import {Button, createStyles, Grid, Paper, Switch, Theme, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {AdvancedSettings} from '../../types/Interfaces';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		themeElement: {
			textAlign: 'center'
		}
	})
);

export interface AdvancedPageProps {
	advancedSettings: AdvancedSettings;
	onAdvancedSettingsUpdate?: (advancedSettings: AdvancedSettings) => void;
}

export function AdvancedSettingsPage(props: AdvancedPageProps): JSX.Element {
	const [extensionChangeConfirmation, setExtensionChangeConfirmation] = React.useState<boolean>(props.advancedSettings.extensionChangeConfirmation);

	const styles: ClassNameMap = useStyles();

	function handleAccept(): void {
		props.onAdvancedSettingsUpdate?.({extensionChangeConfirmation});
	}

	function handleExtensionConfirmationChange(): void {
		setExtensionChangeConfirmation(!extensionChangeConfirmation);
	}

	return <Grid
		container
		direction='column'
		justify='flex-start'
		spacing={4}
		component={Paper}
	>
		<Grid item>
			<Typography variant='h5'><Trans i18nKey='advanced'/></Typography>
		</Grid>
		<Grid
			item
			container
			direction='row'
			justify='flex-start'
			alignItems='baseline'
		>
			<Grid item xs={1} className={styles.themeElement}>
				<Switch
					checked={extensionChangeConfirmation}
					id='theme-change'
					onChange={handleExtensionConfirmationChange}
					color='primary'
					name='checkedB'
					inputProps={{'aria-label': 'primary checkbox'}}
				/>
			</Grid>
			<Grid item className={styles.themeElement}>
				<Trans i18nKey='extension-change-confirmation-description'/>
			</Grid>
		</Grid>
		<Grid
			item
			container
			direction='row'
			justify='center'
			alignItems='flex-start'
			spacing={2}
		>
			<Grid item xs={6}/>
			<Grid item xs={6}>
				<Button
					fullWidth
					id='settings-accept'
					variant='contained'
					onClick={handleAccept}
					disabled={props.advancedSettings.extensionChangeConfirmation === extensionChangeConfirmation}
				>
					<Trans i18nKey='accept'/>
				</Button>
			</Grid>
		</Grid>
	</Grid>;
}
