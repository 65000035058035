import React from 'react';
import {Container, createStyles, CssBaseline, Grid, Theme} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router';
import background from '../../resources/login-background.webp';
import {ServerMediator} from '../server/ServerMediator';
import {TokenStorage} from '../server/TokenStorage';
import {Session} from '../entity/Session';
import {ServerManager} from '../server/ServerManager';
import {initReactI18next} from 'react-i18next';
import i18next from 'i18next';
import en from '../../translations/en.json';
import pl from '../../translations/pl.json';
import de from '../../translations/de.json';
import {LoginForm} from '../components/LoginForm';
import {SnackbarProvider} from 'notistack';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		formWrapper: {
			backgroundColor: '#e3e6f2',
			minHeight: '100vh'
		},
		background: {
			backgroundImage: `url(${background})`,
			height: '100%'
		}
	})
);

export interface LoginAppProps {
	readonly loaded?: boolean;
}

export function LoginApp(props: LoginAppProps): JSX.Element {
	const [counter, setCounter] = React.useState<number>(0);
	const [i18nLoaded, setI18nLoaded] = React.useState<boolean>(props.loaded || false);

	const style: ClassNameMap = useStyles();

	React.useEffect((): void => {
		i18next
			.use(initReactI18next)
			.init({
				lng: 'en',
				fallbackLng: 'en',
				resources: {en, pl, de}
			})
			.finally((): void => setI18nLoaded(true));
	}, []);

	React.useEffect((): void => {
		ServerManager.instance.sessionsRepository.getCurrent()
			.then((session: Session): void => {
				TokenStorage.setSession(session);
				setCounter(counter + 1);
			})
			.catch((): undefined => undefined);
	}, []);

	if (TokenStorage.getSession()) {
		return <Redirect to='/'/>;
	}

	function handleFormSubmit(login: string, password: string): Promise<void> {
		return ServerMediator.obtainToken(login, password)
			.then((session: Session): void => {
				TokenStorage.setSession(session);
				setCounter(counter + 1);
			});
	}

	return <SnackbarProvider maxSnack={3}>
		<div className={style.background}>
			<CssBaseline/>
			{i18nLoaded && <Container maxWidth='sm'>
				<Grid container className={style.formWrapper} alignItems='center'>
					<LoginForm onSubmit={handleFormSubmit} id='login-form'/>
				</Grid>
			</Container>}
		</div>
	</SnackbarProvider>;
}

export default LoginApp;
