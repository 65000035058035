import {DraftNote, Note} from '../../entity/Note';
import {AxiosRequestConfig} from 'axios';
import {EntityRepository} from './EntityRepository';
import {ServerMediator} from '../ServerMediator';

export class NotesRepository extends EntityRepository<number, Note, DraftNote> {
	constructor() {
		super('notes');
	}

	getAll(axiosConfig?: AxiosRequestConfig): Promise<Note[]> {
		return ServerMediator.requestWithToken<Note[]>('GET', this.createRoute(), axiosConfig);
	}

	get(id: number, axiosConfig?: AxiosRequestConfig): Promise<Note> {
		return ServerMediator.requestWithToken<Note>('GET', this.createRoute(id), axiosConfig);
	}

	create(draftNote: DraftNote, axiosConfig: AxiosRequestConfig, project: number): Promise<number> {
		return ServerMediator.requestWithToken<number>('POST', this.createRoute(), axiosConfig, {
			...draftNote,
			project
		});
	}

	update(noteId: number, draftNote: DraftNote, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('PUT', this.createRoute(noteId), axiosConfig, draftNote);
	}

	remove(note: Note, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute(note.id), axiosConfig);
	}
}
