import {DraftProject, Project} from '../../entity/Project';
import {EntityRepository} from './EntityRepository';
import {ServerMediator} from '../ServerMediator';
import {AxiosRequestConfig} from 'axios';

export class ProjectsRepository extends EntityRepository<number, Project, DraftProject> {
	constructor() {
		super('projects');
	}

	getAll(axiosConfig?: AxiosRequestConfig): Promise<Project[]> {
		return ServerMediator.requestWithToken<Project[]>('GET', this.createRoute(), axiosConfig);
	}

	get(id: number, axiosConfig?: AxiosRequestConfig): Promise<Project> {
		return ServerMediator.requestWithToken<Project>('GET', this.createRoute(id), axiosConfig);
	}

	create(draftProject: DraftProject, axiosConfig?: AxiosRequestConfig): Promise<number> {
		return ServerMediator.requestWithToken<number>('POST', this.createRoute(), axiosConfig, draftProject);
	}

	update(id: number, draftProject: Partial<DraftProject>, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('PATCH', this.createRoute(id), axiosConfig, draftProject);
	}

	remove(project: Project, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute(project.id), axiosConfig);
	}
}
