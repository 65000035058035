export interface Config {
	readonly apiHost: string;
	readonly version: string;
	readonly compilationDate: Date;
	readonly mode: string;
	readonly branch: string;
}

export const config: Config = {
	// @ts-ignore
	apiHost: API_HOST,
	// @ts-ignore
	version: VERSION,
	// @ts-ignore
	compilationDate: COMPILATION_DATE,
	// @ts-ignore
	mode: MODE,
	// @ts-ignore
	branch: BRANCH
};

export default config;
