import {Redirect} from 'react-router';
import React from 'react';
import {Button, createStyles, Grid, Paper, TextField, Theme, Typography} from '@material-ui/core';
import {DraftProject} from '../entity/Project';
import {StatusPicker} from '../components/picker/StatusPicker';
import {UserPicker} from '../components/picker/UserPicker';
import {User} from '../entity/User';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {Trans, useTranslation} from 'react-i18next';
import {StatusCode} from '../entity/ProjectStatus';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		gridItem: {
			padding: theme.spacing(1)
		}
	})
);

export interface CreateProjectPageProps {
	me?: User;
	users: User[];
	onProjectCreate?: (draftProject: DraftProject) => Promise<number | undefined>;
}

export function CreateProjectPage(props: CreateProjectPageProps): JSX.Element {
	const [name, setName] = React.useState<string>('');
	const [description, setDescription] = React.useState<string>('');
	const [status, setStatus] = React.useState<StatusCode>(0);
	const [user, setUser] = React.useState<number>(props.me?.id || 1);
	const [newId, setNewId] = React.useState<number | undefined>(undefined);

	const styles: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleNameChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		setName(event.currentTarget.value);
	}

	function handleUserChanged(userId: number): void {
		setUser(userId);
	}

	function handleDescriptionChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		setDescription(event.currentTarget.value);
	}

	function handleStatusChange(newStatus: StatusCode): void {
		setStatus(newStatus);
	}

	function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		props.onProjectCreate?.({name, description, user, status})
			.then(setNewId)
			.then((): string | number => enqueueSnackbar(t('project-created'), {variant: 'success'}))
			.catch((): string | number => enqueueSnackbar(t('unknown-error'), {variant: 'error'}));
	}

	if (newId !== undefined) {
		return <Redirect to={`/project/${newId}`}/>;
	}

	return <div className={styles.content}>
		<form onSubmit={handleFormSubmit} id='create-project-form'>
			<Grid
				className={styles.gridItem}
				container
				component={Paper}
				direction='column'
				justify='space-between'
			>
				<Grid item className={styles.gridItem}>
					<Typography variant='h4'><Trans i18nKey='create-new-project'/></Typography>
				</Grid>
				<Grid
					item
					container
					direction='row'
					justify='flex-start'
					alignItems='center'
				>
					<Grid item xs={9} className={styles.gridItem}>
						<TextField
							id='project-name'
							autoComplete='off'
							label={t('name')}
							variant='outlined'
							onChange={handleNameChanged}
							fullWidth
						/>
					</Grid>
					<Grid item xs={3} className={styles.gridItem}>
						<UserPicker
							name={t('author')}
							users={props.users}
							selectedUser={user}
							onChange={handleUserChanged}
						/>
					</Grid>
				</Grid>
				<Grid item className={styles.gridItem}>
					<TextField
						id='project-description'
						label={t('description')}
						autoComplete='off'
						variant='outlined'
						onChange={handleDescriptionChanged}
						multiline
						rowsMax={10}
						rows={4}
						fullWidth
					/>
				</Grid>
				<Grid item className={styles.gridItem}>
					<StatusPicker
						name={t('status')}
						status={status}
						onChange={handleStatusChange}
					/>
				</Grid>
				<Grid item className={styles.gridItem}>
					<Button
						variant='contained'
						fullWidth
						type='submit'
						disabled={name === '' || description === ''}
					>
						<Trans i18nKey='create'/>
					</Button>
				</Grid>
			</Grid>
		</form>
	</div>;
}

export default CreateProjectPage;
