import React from 'react';
import {Button, createStyles, Grid, PaletteType, Paper, Switch, Theme, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {LanguagePicker} from '../../components/picker/LanguagePicker';
import {LanguageCode} from '../../entity/Language';
import {DateFormatPicker} from '../../components/picker/DateFormatPicker';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {DateFormat, Preferences, TimeFormat} from '../../types/Interfaces';
import {TimeFormatPicker} from '../../components/picker/TimeFormatPicker';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		themeElement: {
			textAlign: 'center'
		}
	})
);

export interface PreferencesSettingsPageProps {
	preferences: Preferences;
	onPreferencesUpdate?: (preferences: Preferences) => void;
}

export function PreferencesSettingsPage(props: PreferencesSettingsPageProps): JSX.Element {
	const [theme, setTheme] = React.useState<PaletteType>(props.preferences.theme);
	const [language, setLanguage] = React.useState<LanguageCode>(props.preferences.language);
	const [dateFormat, setDateFormat] = React.useState<DateFormat>(props.preferences.dateFormat);
	const [timeFormat, setTimeFormat] = React.useState<TimeFormat>(props.preferences.timeFormat);

	const styles: ClassNameMap = useStyles();
	const {t} = useTranslation();

	function handleAccept(): void {
		props.onPreferencesUpdate?.({
			dateFormat,
			timeFormat,
			language,
			theme
		});
	}

	function handleThemeChange(): void {
		setTheme(theme === 'dark' ? 'light' : 'dark');
	}

	function preferencesChanged(): boolean {
		return props.preferences.theme !== theme
			|| props.preferences.language !== language
			|| props.preferences.dateFormat !== dateFormat
			|| props.preferences.timeFormat !== timeFormat;
	}

	return <Grid
		container
		direction='column'
		justify='flex-start'
		spacing={4}
		component={Paper}
	>
		<Grid item>
			<Typography variant='h5'><Trans i18nKey='preferences'/></Typography>
		</Grid>
		<Grid
			item
			container
			direction='row'
			justify='flex-start'
			alignItems='baseline'
		>
			<Grid item xs={1} className={styles.themeElement}>
				<Trans i18nKey='light-theme'/>
			</Grid>
			<Grid item className={styles.themeElement}>
				<Switch
					checked={theme === 'dark'}
					id='theme-change'
					onChange={handleThemeChange}
					color='primary'
					name='checkedB'
					inputProps={{'aria-label': 'primary checkbox'}}
				/>
			</Grid>
			<Grid item xs={1} className={styles.themeElement}>
				<Trans i18nKey='dark-theme'/>
			</Grid>
		</Grid>
		<Grid item>
			<LanguagePicker
				name={t('language')}
				selectedLanguage={language}
				onChange={setLanguage}
			/>
		</Grid>
		<Grid
			item
			container
			justify='flex-start'
			alignItems='flex-start'
			spacing={1}
		>
			<Grid item xs={6}>
				<DateFormatPicker
					name={t('date-format')}
					selectedFormat={dateFormat}
					onChange={setDateFormat}
				/>
			</Grid>
			<Grid item xs={6}>
				<TimeFormatPicker
					name={t('time-format')}
					selectedFormat={timeFormat}
					onChange={setTimeFormat}
				/>
			</Grid>
			<Grid
				item
				container
				direction='row'
				justify='center'
				alignItems='flex-start'
				spacing={2}
			>
				<Grid item xs={6}/>
				<Grid item xs={6}>
					<Button
						fullWidth
						id='settings-accept'
						variant='contained'
						onClick={handleAccept}
						disabled={!preferencesChanged()}
					>
						<Trans i18nKey='accept'/>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</Grid>;
}
