import config from '../Config';
import {ProjectsRepository} from './repository/ProjectsRepository';
import {NotesRepository} from './repository/NotesRepository';
import {AttachmentsRepository} from './repository/AttachmentsRepository';
import {UsersRepository} from './repository/UsersRepository';
import {User} from '../entity/User';
import {Project} from '../entity/Project';
import {Note} from '../entity/Note';
import {Attachment} from '../entity/Attachment';
import {ServerMediator} from './ServerMediator';
import {SessionsRepository} from './repository/SessionsRepository';
import {Settings} from '../types/Interfaces';

interface ServerEnvironment {
	maxUpload: number;
}

export interface ProkerResponse<T> {
	data: T;
}

export type EndpointType =
	'projects'
	| 'users'
	| 'notes'
	| 'attachments'
	| 'sessions';

export interface ServerResource {
	projects: Project[];
	users: User[];
	notes: Note[];
	attachments: Attachment[];
	me?: User;
	settings?: Settings;
}

export class ServerManager {
	private static _instance: ServerManager = new ServerManager();

	private readonly _projectRepository: ProjectsRepository = new ProjectsRepository();
	private readonly _notesRepository: NotesRepository = new NotesRepository();
	private readonly _attachmentsRepository: AttachmentsRepository = new AttachmentsRepository();
	private readonly _usersRepository: UsersRepository = new UsersRepository();
	private readonly _sessionsRepository: SessionsRepository = new SessionsRepository();

	private _serverEnvironment?: ServerEnvironment;

	private constructor() {
	}

	get projectRepository(): ProjectsRepository {
		return this._projectRepository;
	}

	get notesRepository(): NotesRepository {
		return this._notesRepository;
	}

	get attachmentsRepository(): AttachmentsRepository {
		return this._attachmentsRepository;
	}

	get usersRepository(): UsersRepository {
		return this._usersRepository;
	}

	get sessionsRepository(): SessionsRepository {
		return this._sessionsRepository;
	}

	init(): Promise<ServerResource> {
		const combined: ServerResource = {
			projects: [],
			users: [],
			notes: [],
			attachments: []
		};
		return ServerMediator.getCurrentSession().then((): Promise<ServerResource> =>
			Promise.all([
				this.projectRepository.getAll().then((projects: Project[]): Project[] => combined.projects = projects),
				this.usersRepository.getAll().then((users: User[]): User[] => combined.users = users),
				this.notesRepository.getAll().then((notes: Note[]): Note[] => combined.notes = notes),
				this.attachmentsRepository.getAll().then((attachments: Attachment[]): Attachment[] => combined.attachments = attachments),
				this.usersRepository.getMe().then((me: User): User => combined.me = me),
				this.usersRepository.getSettings().then((settings: Settings): Settings => combined.settings = settings),
				this.requestServerEnvironment()
			]).then((): ServerResource => combined));
	}

	getMaxUpload(): number {
		return this.serverEnvironment.maxUpload;
	}

	requestServerEnvironment(): Promise<void> {
		if (this._serverEnvironment) {
			return Promise.resolve();
		}
		return ServerMediator.requestWithToken<{ server: ServerEnvironment }>('GET', config.apiHost)
			.then((response: { server: ServerEnvironment }): void => {
				this._serverEnvironment = response.server;
			});
	}

	private get serverEnvironment(): ServerEnvironment {
		return this._serverEnvironment || {maxUpload: 0};
	}

	static get instance(): ServerManager {
		return this._instance;
	}

	static reinitialize(): void {
		ServerManager._instance = new ServerManager();
	}
}
