import React from 'react';
import {DraftNote, Note} from '../../entity/Note';
import {
	Breadcrumbs,
	Button,
	createStyles,
	Divider,
	Grid,
	Link,
	Paper,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {Project} from '../../entity/Project';
import {User} from '../../entity/User';
import {Link as Anchor} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		gridItem: {
			padding: theme.spacing(1)
		}
	})
);

export interface SingleNotePageProps {
	onConfirm?: (note: DraftNote) => void;
	onCancel?: () => void;
	note?: Note;
	project: Project;
	user: User;
}

export function SingleNotePage(props: SingleNotePageProps): JSX.Element {
	const [title, setTitle] = React.useState<string>(props.note?.title || '');
	const [content, setContent] = React.useState<string>(props.note?.content || '');

	const style: ClassNameMap = useStyles();
	const {t} = useTranslation();

	function handleCancel(): void {
		setTitle(props.note?.title || '');
		setContent(props.note?.content || '');
		props.onCancel?.();
	}

	function handleFormSubmit(event: React.MouseEvent<HTMLFormElement>): void {
		event.preventDefault();
		if (title !== '') {
			props.onConfirm?.({title, content, project: props.project.id, user: props.user.id});
		}
	}

	function handleNoteTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setTitle(event.target.value);
	}

	function handleNoteContentChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setContent(event.target.value);
	}

	return (
		<Grid
			container
			direction='column'
			justify='flex-start'
			spacing={1}
		>
			<Grid item>
				<Breadcrumbs separator='›' aria-label='breadcrumb'>
					<Link component={Anchor} to='/project'>
						<Trans i18nKey='projects'/>
					</Link>
					<Link component={Anchor} to={`/project/${props.project.id}`}>
						{props.project.name}
					</Link>
					<Link component={Anchor} to={`/project/${props.project.id}/note`}>
						<Trans i18nKey='notes'/>
					</Link>
					<Typography color='textPrimary'><Trans i18nKey='edit'/></Typography>
				</Breadcrumbs>
			</Grid>
			<Grid item>
				<form onSubmit={handleFormSubmit} id='note-form'>
					<Grid
						component={Paper}
						container
						direction='column'
						justify='flex-start'
					>
						<Grid item className={style.gridItem}>
							<TextField
								id='note-title'
								type='text'
								autoComplete='off'
								variant='outlined'
								label={t('title')}
								defaultValue={title}
								onChange={handleNoteTitleChange}
								fullWidth
							/>
						</Grid>
						<Divider/>
						<Grid item className={style.gridItem}>
							<TextField
								id='note-content'
								type='text'
								autoComplete='off'
								label={t('content')}
								rows={5}
								rowsMax={10}
								defaultValue={content}
								multiline
								variant='outlined'
								onChange={handleNoteContentChange}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							className={style.gridItem}
							container
							direction='row'
							justify='flex-end'
							spacing={1}
						>
							<Grid item xs={3}>
								<Button
									id='note-cancel'
									fullWidth
									variant='outlined'
									onClick={handleCancel}
								>
									<Trans i18nKey='cancel'/>
								</Button>
							</Grid>
							<Grid item xs={3}>
								<Button
									disabled={
										title === '' ||
										content === '' ||
										(title === props.note?.title && content === props.note?.content)
									}
									fullWidth
									type='submit'
									id='note-create'
									variant='contained'
								>
									<Trans i18nKey='ok'/>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
}
