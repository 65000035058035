import React from 'react';
import {Button, createStyles, Grid, Paper, TextField, Theme, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {User} from '../../entity/User';
import {Line} from 'rc-progress';
import {getPassphraseData, PassphraseData, PassphraseStrength} from '../../SecurityUtils';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {AxiosError} from 'axios';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules => createStyles({
	line: {
		width: '100%',
		height: '5px'
	}
}));

export interface SettingsPageProps {
	user: User;
	onCredentialsUpdate?: (user: User, oldPassword: string, newPassword: string) => Promise<void>;
}

export function SecuritySettingsPage(props: SettingsPageProps): JSX.Element {
	const [oldPassword, setOldPassword] = React.useState<string>('');
	const [oldPasswordError, setOldPasswordError] = React.useState<boolean>(false);
	const [password1Error, setPassword1Error] = React.useState<boolean>(false);
	const [password1, setPassword1] = React.useState<string>('');
	const [password2, setPassword2] = React.useState<string>('');
	const [disabledButton, setDisabledButton] = React.useState<boolean>(true);

	const styles: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	React.useEffect((): void => {
		setDisabledButton(!verifyMinimalPasswordRequirements() || oldPassword === '' || password1 !== password2);
	}, [oldPassword, password1, password2]);

	function handleOldPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setOldPassword(event.target.value);
		setOldPasswordError(false);
	}

	function handlePassword1Change(event: React.ChangeEvent<HTMLInputElement>): void {
		setPassword1(event.target.value);
		setPassword1Error(false);
	}

	function handlePassword2Change(event: React.ChangeEvent<HTMLInputElement>): void {
		setPassword2(event.target.value);
	}

	function verifyMinimalPasswordRequirements(): boolean {
		const passphraseData: PassphraseData | undefined = getPassphraseData(password1);
		if (!passphraseData) {
			return false;
		}
		if (!passphraseData.strength) {
			return false;
		}
		return passphraseData.strength !== 'WEAKER';
	}

	function handlePasswordChange(): void {
		if (!oldPassword) {
			setOldPasswordError(true);
		} else if (password1) {
			setOldPassword('');
			setPassword1('');
			setPassword2('');
			setDisabledButton(true);
			props.onCredentialsUpdate?.(props.user, oldPassword, password1)
				.then((): string | number => enqueueSnackbar(t('password-changed'), {variant: 'success'}))
				.catch((error: AxiosError): void => {
					const oldPasswordMessages: string[] = error.response?.data?.errors?.children?.oldPassword?.errors;
					if (oldPasswordMessages && oldPasswordMessages.length) {
						setOldPasswordError(true);
						enqueueSnackbar(t('old-password-incorrect'), {variant: 'error'});
					}
					const passwordMessages: string[] = error.response?.data?.errors?.children?.password?.errors;
					if (passwordMessages && passwordMessages.length) {
						setPassword1Error(true);
						enqueueSnackbar(t('password-too-simple'), {variant: 'error'});
					}
				})
				.finally((): void => setDisabledButton(false));
		}
	}

	function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
		event.preventDefault();
	}

	function renderStrengthIndicator(): JSX.Element {
		const data: PassphraseData | undefined = getPassphraseData(password1);

		if (!data) {
			return <Line
				percent={0}
				strokeColor='#D9D9D9'
				className={styles.line}
			/>;
		}

		const strength: PassphraseStrength = data.strength;

		if (strength === 'WEAKER') {
			return <Line
				percent={20}
				strokeColor='#FF0000'
				className={styles.line}
			/>;
		}

		if (strength === 'WEAK') {
			return <Line
				percent={40}
				strokeColor='#FF8800'
				className={styles.line}
			/>;
		}

		if (strength === 'STRONG') {
			return <Line
				percent={80}
				strokeColor='#BBFF00'
				className={styles.line}
			/>;
		}

		if (strength === 'STRONGER') {
			return <Line
				percent={100}
				strokeColor='#00FF00'
				className={styles.line}
			/>;
		}

		// Moderate

		return <Line
			percent={60}
			strokeColor='#FFFF00'
			className={styles.line}
		/>;
	}

	return <form onSubmit={handleFormSubmit} id='form-change-password'>
		<Grid
			container
			direction='column'
			justify='flex-start'
			spacing={4}
			component={Paper}
		>
			<Grid item>
				<Typography variant='h5'><Trans i18nKey='security'/></Typography>
			</Grid>
			<Grid item>
				<TextField
					id='user-old-password'
					value={oldPassword}
					type='password'
					error={oldPasswordError}
					autoComplete='off'
					variant='outlined'
					label={t('old-password')}
					onChange={handleOldPasswordChange}
					fullWidth
				/>
			</Grid>
			<Grid item>
				<TextField
					id='user-password-1'
					type='password'
					value={password1}
					autoComplete='off'
					error={password1Error}
					variant='outlined'
					label={t('new-password')}
					onChange={handlePassword1Change}
					fullWidth
				/>
				{renderStrengthIndicator()}
			</Grid>
			<Grid item>
				<TextField
					id='user-password-2'
					type='password'
					value={password2}
					autoComplete='off'
					label={t('repeat-new-password')}
					variant='outlined'
					onChange={handlePassword2Change}
					fullWidth
				/>
			</Grid>
			<Grid
				item
				container
				direction='row'
				justify='center'
				alignItems='flex-start'
				spacing={2}
			>
				<Grid item xs={6}/>
				<Grid item xs={6}>
					<Button
						fullWidth
						id='change-password-confirm'
						variant='contained'
						disabled={disabledButton}
						onClick={handlePasswordChange}
					>
						<Trans i18nKey='accept'/>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</form>;
}
