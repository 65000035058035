import React from 'react';
import {Route} from 'react-router-dom';
import {Redirect, RouteComponentProps} from 'react-router';
import {NavBar} from '../components/NavBar';
import ProjectsPage from '../pages/ProjectsPage';
import {DraftProject, Project} from '../entity/Project';
import SingleProjectPage from '../pages/SingleProjectPage';
import CreateProjectPage from '../pages/CreateProjectPage';
import {CssBaseline, Theme, ThemeProvider} from '@material-ui/core';
import {ServerManager, ServerResource} from '../server/ServerManager';
import {DraftUser, User} from '../entity/User';
import {DraftNote, Note} from '../entity/Note';
import {Attachment, DraftAttachment} from '../entity/Attachment';
import ProfilePage from '../pages/ProfilePage';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {SnackbarProvider} from 'notistack';
import {Interstitial} from '../components/Interstitial';
import {TokenStorage} from '../server/TokenStorage';
import {AxiosError, AxiosRequestConfig} from 'axios';
import {SettingsPage} from '../pages/SettingsPage';
import i18next from 'i18next';
import {Settings} from '../types/Interfaces';
import {initReactI18next} from 'react-i18next';
import en from '../../translations/en.json';
import pl from '../../translations/pl.json';
import de from '../../translations/de.json';

export function IndexApp(): JSX.Element {
	const [projects, setProjects] = React.useState<Project[]>([]);
	const [users, setUsers] = React.useState<User[]>([]);
	const [notes, setNotes] = React.useState<Note[]>([]);
	const [attachments, setAttachments] = React.useState<Attachment[]>([]);
	const [me, setMe] = React.useState<User | undefined>(undefined);
	const [authorized, setAuthorized] = React.useState<boolean>(true);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [settings, setSettings] = React.useState<Settings | undefined>();

	React.useEffect((): void => {
		i18next
			.use(initReactI18next)
			.init({
				lng: 'en',
				fallbackLng: 'en',
				resources: {en, pl, de}
			});
	}, []);

	const theme: Theme = createMuiTheme({
		palette: {
			type: settings?.theme || 'light',
			appBar: {
				foreground: settings?.theme === 'dark' ? '#ffffff' : '#000000',
				background: settings?.theme === 'dark' ? '#555555' : '#efefef',
				iconColor: settings?.theme === 'dark' ? '#ffffff' : '#333333'
			},
			primary: {
				contrastText: 'rgba(0, 0, 0, 0.87)',
				dark: 'rgb(100, 141, 174)',
				light: 'rgb(166, 212, 250)',
				main: settings?.theme === 'dark' ? '#90caf9' : '#2b5b83'
			},
			secondary: {
				contrastText: 'rgba(0, 0, 0, 0.87)',
				dark: 'rgb(170, 100, 123)',
				light: 'rgb(246, 165, 192)',
				main: '#f48fb1'
			}
		}
	});

	React.useEffect((): void => {
		i18next.changeLanguage(settings?.language || 'en');
	}, [settings]);

	React.useEffect((): void => {
		setLoading(true);
		if (!TokenStorage.getToken()) {
			setAuthorized(false);
			return;
		}
		ServerManager.instance.sessionsRepository.getCurrent()
			.then(TokenStorage.setSession)
			.then((): void => {
				if (!TokenStorage.getSession()) {
					setAuthorized(false);
					return;
				}
				fetchData()
					.catch((): void => setAuthorized(false))
					.finally((): void => setLoading(false));
			}).catch((): void => setAuthorized(false));
	}, []);

	React.useEffect((): void => {
		if (!authorized) {
			TokenStorage.setToken(undefined);
			TokenStorage.setSession(undefined);
		}
	}, [authorized]);

	if (!authorized) {
		return <Redirect to='/login'/>;
	}

	function handleProjectCreate(draftProject: DraftProject): Promise<number | undefined> {
		setLoading(true);
		return ServerManager.instance.projectRepository.create(draftProject)
			.then((response: number): Promise<number> => ServerManager.instance.projectRepository.getAll()
				.then(setProjects)
				.catch(handleRequestError)
				.then((): number => response))
			.catch(handleRequestError)
			.then((value: number | void): number | undefined => value || undefined)
			.finally((): void => setLoading(false));
	}

	function handleProjectUpdate(project: Project, draftProject: Partial<DraftProject>): Promise<void> {
		setLoading(true);
		return ServerManager.instance.projectRepository.update(project.id, draftProject)
			.then((): Promise<void> => ServerManager.instance.projectRepository.getAll().then(setProjects).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleProjectDelete(deleting: Project): Promise<void> {
		setLoading(true);
		return ServerManager.instance.projectRepository.remove(deleting)
			.then((): Promise<void> => ServerManager.instance.projectRepository.getAll().then(setProjects).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleNoteCreate(draftNote: DraftNote, projectId: number): Promise<void> {
		setLoading(true);
		return ServerManager.instance.notesRepository.create(draftNote, {}, projectId)
			.then((): Promise<void> => ServerManager.instance.notesRepository.getAll().then(setNotes).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleNoteUpdate(note: Note, draftNote: DraftNote): Promise<void> {
		setLoading(true);
		return ServerManager.instance.notesRepository.update(note.id, draftNote)
			.then((): Promise<void> => ServerManager.instance.notesRepository.getAll().then(setNotes).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleNoteDelete(deleting: Note): Promise<void> {
		setLoading(true);
		return ServerManager.instance.notesRepository.remove(deleting)
			.then((): Promise<void> => ServerManager.instance.notesRepository.getAll().then(setNotes).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleAttachmentUpload(formData: FormData, config: AxiosRequestConfig): Promise<void> {
		return ServerManager.instance.attachmentsRepository.create(formData, config)
			.then((): Promise<void> => ServerManager.instance.attachmentsRepository.getAll().then(setAttachments).catch(handleRequestError))
			.catch(handleRequestError);
	}

	function handleAttachmentEdit(editing: Attachment, draftAttachment: DraftAttachment): Promise<void> {
		setLoading(true);
		return ServerManager.instance.attachmentsRepository.update(editing.uuid, draftAttachment)
			.then((): Promise<void> => ServerManager.instance.attachmentsRepository.getAll().then(setAttachments).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleAttachmentDelete(deleting: Attachment): Promise<void> {
		setLoading(true);
		return ServerManager.instance.attachmentsRepository.remove(deleting)
			.then((): Promise<void> => ServerManager.instance.attachmentsRepository.getAll().then(setAttachments).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleCredentialsUpdate(user: User, oldPassword: string, newPassword: string): Promise<void> {
		setLoading(true);
		return ServerManager.instance.usersRepository.changePassword(user.id, oldPassword, newPassword)
			.then((): Promise<void> => ServerManager.instance.usersRepository.getAll().then(setUsers).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleUserUpdate(user: User, draftUser: Partial<DraftUser>): Promise<void> {
		setLoading(true);
		return ServerManager.instance.usersRepository.update(user.id, draftUser)
			.then((): Promise<void> => ServerManager.instance.usersRepository.getAll().then(setUsers).catch(handleRequestError))
			.then((): Promise<void> => ServerManager.instance.usersRepository.getMe().then(setMe).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleSettingsUpdate(newSettings: Partial<Settings>): Promise<void> {
		setLoading(true);
		return ServerManager.instance.usersRepository.setSettings(newSettings)
			.then((): Promise<void> => ServerManager.instance.usersRepository.getSettings().then(setSettings).catch(handleRequestError))
			.catch(handleRequestError)
			.finally((): void => setLoading(false));
	}

	function handleLogoutClick(): void {
		setAuthorized(false);
	}

	function handleRequestError(error: AxiosError): void {
		if (error.response?.status === 401) {
			setAuthorized(false);
		}
		throw error;
	}

	function handleRefresh(): void {
		setLoading(true);
		fetchData()
			.catch((): void => setAuthorized(false))
			.finally((): void => setLoading(false));
	}

	function fetchData(): Promise<void> {
		return ServerManager.instance.init().then((response: ServerResource): void => {
			setProjects(response.projects);
			setUsers(response.users);
			setNotes(response.notes);
			setAttachments(response.attachments);
			setMe(response.me);
			setSettings(response.settings || settings);
		}).catch(handleRequestError);
	}

	return <ThemeProvider theme={theme}>
		<SnackbarProvider maxSnack={3}>
			<CssBaseline/>
			<Interstitial open={loading}/>
			{me && settings && <>
				<NavBar
					user={me}
					onLogoutClick={handleLogoutClick}
					onProjectsRefresh={handleRefresh}
					settings={settings}
				/>
				<main>
					<Route path={['/', '/project']} exact>
						<ProjectsPage
							projects={projects}
							onProjectRemove={handleProjectDelete}
						/>
					</Route>
					<Route
						id='user'
						path='/user/:id'
						render={(props: RouteComponentProps<{ id: string }>): JSX.Element | undefined => {
							const userId: number = +props.match.params.id;
							const user: User | undefined = users.find((u: User): boolean => u.id === userId);
							if (!user) {
								return undefined;
							}
							return <ProfilePage
								user={user}
								projects={projects.filter((p: Project): boolean => p.user === user?.id)}
							/>;
						}}
					/>
					<Route path='/settings'>
						<SettingsPage
							user={me}
							onCredentialsUpdate={handleCredentialsUpdate}
							onUserUpdate={handleUserUpdate}
							settings={settings}
							onSettingsUpdate={handleSettingsUpdate}
						/>
					</Route>
					<Route path='/project/create' exact>
						<CreateProjectPage
							users={users}
							me={me}
							onProjectCreate={handleProjectCreate}
						/>
					</Route>
					<Route
						id='project'
						path='/project/:id'
						render={(props: RouteComponentProps<{ id: string }>): JSX.Element | undefined => {
							const projectId: number = +props.match.params.id;
							const project: Project | undefined = projects.find((p: Project): boolean => p.id === projectId);
							if (!project || !settings) {
								return undefined;
							}
							return <SingleProjectPage
								user={me}
								project={project}
								users={users}
								onProjectUpdate={handleProjectUpdate}
								notes={notes.filter((note: Note): boolean => note.project === projectId)}
								onNoteCreate={handleNoteCreate}
								onNoteUpdate={handleNoteUpdate}
								onNoteDelete={handleNoteDelete}
								attachments={attachments.filter((attachment: Attachment): boolean => attachment.project === projectId)}
								onAttachmentUpload={handleAttachmentUpload}
								onAttachmentUpdate={handleAttachmentEdit}
								onAttachmentDelete={handleAttachmentDelete}
								settings={settings}
							/>;
						}}
					/>
				</main>
			</>}
		</SnackbarProvider>
	</ThemeProvider>;
}

export default IndexApp;
