import React, {useCallback} from 'react';
import {DropzoneState, useDropzone} from 'react-dropzone';
import {Attachment, DraftAttachment} from '../../entity/Attachment';
import {
	Backdrop,
	Breadcrumbs,
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Tooltip,
	Typography
} from '@material-ui/core';
import {ServerManager} from '../../server/ServerManager';
import {Project} from '../../entity/Project';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {ConfirmationDialog} from '../../components/ConfirmationDialog';
import {User} from '../../entity/User';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {Link as Anchor} from 'react-router-dom';
import {AxiosRequestConfig} from 'axios';
import {ServerMediator} from '../../server/ServerMediator';
import {countUnit, extractExtension, getFileImage} from '../../Utils';
import {FilePicker} from '../../components/FilePicker';
import _ from 'lodash';
import {Trans, useTranslation} from 'react-i18next';
import {getActualLanguage} from '../../entity/Language';
import {format} from 'date-fns';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		columnType: {
			width: '50px',
			maxWidth: '50px',
			padding: '6px 8px 6px 24px'
		},
		columnName: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			maxWidth: '500px'
		},
		columnDate: {
			width: '250px',
			maxWidth: '250px'
		},
		columnUploader: {
			width: '150px',
			maxWidth: '150px'
		},
		columnButtons: {
			width: '150px',
			maxWidth: '150px'
		},
		backdrop: {
			animation: `$example 1000ms infinite`,
			zIndex: 10000,
			boxSizing: 'border-box',
			border: `${theme.palette.appBar?.background} solid 10px`,
			boxShadow: 'inset 0 0 20px #000000'
		},
		'@keyframes example': {
			'0%': {
				borderWidth: '10px'
			},
			'50%': {
				borderWidth: '20px'
			},
			'100%': {
				borderWidth: '10px'
			}
		},
		userLink: {
			textDecoration: 'none',
			color: 'inherit',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	})
);

export interface AttachmentPageProps {
	project: Project;
	attachments: Attachment[];
	onAttachmentUpload?: (formData: FormData, config: AxiosRequestConfig) => Promise<void>;
	onAttachmentUpdate?: (attachment: Attachment, draftAttachment: DraftAttachment) => Promise<void>;
	onAttachmentDelete?: (attachment: Attachment) => Promise<void>;
	users: User[];
	user: User;
	dateTimeFormat: string;
	extensionChangeConfirmation?: boolean;
}

export function AttachmentPage(props: AttachmentPageProps): JSX.Element {
	const [deleting, setDeleting] = React.useState<Attachment | undefined>(undefined);
	const [editing, setEditing] = React.useState<Attachment | undefined>(undefined);
	const [newName, setNewName] = React.useState<string | undefined>(undefined);
	const [missingAttachmentName, setMissingAttachmentName] = React.useState<boolean>(false);
	const [files, setFiles] = React.useState<File[] | undefined>();
	const [uploadingIndex, setUploadingIndex] = React.useState<number>(0);
	const [progress, setProgress] = React.useState<number>(0);
	const [tooLargeFiles, setTooLargeFiles] = React.useState<File[]>([]);
	const [newExtension, setNewExtension] = React.useState<string | undefined>(undefined);

	const style: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	React.useEffect(upload, [files, uploadingIndex]);

	const dropzoneState: DropzoneState = useDropzone({
		onDrop: useCallback(analyzeFiles, [])
	});

	const config: AxiosRequestConfig = {
		onUploadProgress: (progressEvent: ProgressEvent): void => {
			setProgress((progressEvent.loaded * 100) / progressEvent.total);
		},
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};

	function getCurrentUploadingFile(): File | undefined {
		if (files !== undefined) {
			return files[uploadingIndex];
		}
		return undefined;
	}

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const localFileList: FileList | null = event.target.files;
		analyzeFiles(localFileList ? Array.from(localFileList) : undefined);
	}

	function analyzeFiles(fileArray: File[] | undefined): void {
		if (!fileArray || !fileArray.length) {
			return;
		}
		const [smallFiles, largeFiles] = _.partition(fileArray, (f: File): boolean => f.size < ServerManager.instance.getMaxUpload());
		if (smallFiles.length !== 0) {
			setFiles(smallFiles);
			setUploadingIndex(0);
		}
		setTooLargeFiles(largeFiles);
	}

	function continueUpload(): void {
		if (files !== undefined && uploadingIndex + 1 < files.length) {
			setUploadingIndex(uploadingIndex + 1);
		} else {
			enqueueSnackbar(`Uploaded ${uploadingIndex + 1} ${uploadingIndex === 0 ? 'file' : 'files'}.`, {variant: 'success'});
			setFiles(undefined);
		}
	}

	function upload(): void {
		const uploadingFile: File | undefined = getCurrentUploadingFile();
		if (uploadingFile) {
			const formData: FormData = new FormData();
			formData.append('file', uploadingFile, uploadingFile.name);
			formData.append('project', props.project.id.toString());
			props.onAttachmentUpload?.(formData, config).then(continueUpload);
		}
	}

	function handleProblemClose(): void {
		setTooLargeFiles([]);
	}

	function handleDeleteCancel(): void {
		setDeleting(undefined);
	}

	function handleEditCancel(): void {
		setMissingAttachmentName(false);
		setNewName(undefined);
		setNewExtension(undefined);
	}

	function handleExtensionChangeCancel(): void {
		setNewExtension(undefined);
	}

	function executeUpdate(): void {
		if (!newName || newName === '' || !editing) {
			setMissingAttachmentName(true);
			return;
		}
		setNewName(undefined);
		setNewExtension(undefined);
		props.onAttachmentUpdate?.(editing, {name: newName})
			.then((): string | number => enqueueSnackbar(t('attachment-updated'), {variant: 'success'}))
			.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}))
			.finally((): void => setEditing(undefined));
	}

	function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		if (!newName || newName === '' || !editing) {
			setMissingAttachmentName(true);
			return;
		}
		const newExt: string = extractExtension(newName);
		const oldExt: string = extractExtension(editing.name);
		if (!props.extensionChangeConfirmation || oldExt === newExt) {
			executeUpdate();
		} else {
			setNewExtension(newExt);
		}
	}

	function handleAttachmentNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setMissingAttachmentName(false);
		setNewName(event.currentTarget.value);
	}

	function handleAttachmentDelete(): void {
		setDeleting(undefined);
		if (deleting) {
			props.onAttachmentDelete?.(deleting)
				.then((): string | number => enqueueSnackbar(t('attachment-deleted'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}));
		}
	}

	function generateAuthor(attachment: Attachment): JSX.Element {
		const found: User | undefined = props.users.find((u: User): boolean => u.id === attachment.user);
		return <Anchor to={`/user/${found?.id}`} className={style.userLink}>
			{found?.displayName}
		</Anchor>;
	}

	function renderIcon(attachment: Attachment): JSX.Element {
		const IconComponent: () => JSX.Element = getFileImage(attachment.name.substring(attachment.name.lastIndexOf('.') + 1));
		return <IconComponent/>;
	}

	return (
		<div {...dropzoneState.getRootProps()} tabIndex={undefined}>
			<Backdrop open={dropzoneState.isDragActive} className={style.backdrop}>
				<Typography variant='h6'><Trans i18nKey='drop-your-files-anywhere'/></Typography>
			</Backdrop>
			<Grid
				container
				direction='column'
				justify='flex-start'
				spacing={1}
			>
				<Grid
					item
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={Anchor} to='/project'>
								<Trans i18nKey='projects'/>
							</Link>
							<Link component={Anchor} to={`/project/${props.project.id}`}>
								{props.project.name}
							</Link>
							<Typography color='textPrimary'><Trans i18nKey='attachments'/></Typography>
						</Breadcrumbs>
					</Grid>
					<Grid item>
						<FilePicker onFileChange={handleFileChange} disabled={!!files}/>
					</Grid>
				</Grid>
				<Grid item>
					{files && <>
						[{uploadingIndex + 1}/{files?.length}] {getCurrentUploadingFile()?.name}
						<LinearProgress variant='determinate' value={progress}/>
					</>}
					<TableContainer component={Paper}>
						<Table aria-label='attachments' size='small'>
							<TableHead>
								<TableRow>
									<TableCell
										className={style.columnName}
										colSpan={2}
									><Trans i18nKey='name'/></TableCell>
									<TableCell className={style.columnDate}><Trans i18nKey='date'/></TableCell>
									<TableCell className={style.columnUploader}><Trans i18nKey='author'/></TableCell>
									<TableCell className={style.columnButtons}/>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.attachments.map((attachment: Attachment, index: number): JSX.Element =>
									<TableRow key={index}>
										<TableCell className={style.columnType} component='th' scope='row'>
											{renderIcon(attachment)}
										</TableCell>
										<TableCell className={style.columnName} component='th' scope='row'>
											{attachment.name}
										</TableCell>
										<TableCell className={style.columnDate}>
											{format(new Date(attachment.date), props.dateTimeFormat, {
												locale: getActualLanguage().locale
											})}
										</TableCell>
										<TableCell className={style.columnUploader}>
											{generateAuthor(attachment)}
										</TableCell>
										<TableCell className={style.columnButtons} align='right'>
											<Grid
												container
												direction='row'
												justify='space-between'
												alignItems='center'
											>
												<Grid item>
													<Tooltip title={t<string>('download')} arrow>
														<a
															href={ServerMediator.authorizeUrl(attachment.location)}
															download
														>
															<IconButton size='small' aria-label='download'>
																<GetAppIcon/>
															</IconButton>
														</a>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title={t<string>('rename')} arrow>
														<IconButton
															id='attachment-edit'
															size='small'
															onClick={(): void => {
																setNewName(attachment.name);
																setEditing(attachment);
															}}
														>
															<EditIcon/>
														</IconButton>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title={t<string>('delete')} arrow>
														<IconButton
															id='attachment-delete'
															size='small'
															onClick={(): void => setDeleting(attachment)}
														>
															<DeleteIcon/>
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<ConfirmationDialog
				id='confirmation-delete'
				open={deleting !== undefined}
				onCancel={handleDeleteCancel}
				onConfirm={handleAttachmentDelete}
				title={t('are-you-sure-deleting')}
				content={<ul>
					<li>{deleting?.name}</li>
				</ul>}
				positiveText={t('delete')}
			/>
			<ConfirmationDialog
				id='confirmation-extension'
				open={newExtension !== undefined}
				onCancel={handleExtensionChangeCancel}
				onConfirm={executeUpdate}
				title={t('are-you-sure')}
				content={t('are-you-sure-extension')}
			/>
			<Dialog
				open={newName !== undefined}
				fullWidth
				maxWidth={'sm'}
				onClose={handleEditCancel}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'><Trans i18nKey='edit'/></DialogTitle>
				<DialogContent id='alert-dialog-description'>
					<form id='attachment-dialog-form' onSubmit={handleFormSubmit}>
						<TextField
							id='attachment-name'
							type='text'
							autoComplete='off'
							variant='outlined'
							label={t('name')}
							size='medium'
							defaultValue={newName}
							error={missingAttachmentName}
							onChange={handleAttachmentNameChange}
							fullWidth
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button
						id='attachment-edit-cancel'
						form='attachment-dialog-form'
						onClick={handleEditCancel}
						variant='outlined'
					>
						<Trans i18nKey='cancel'/>
					</Button>
					<Button
						form='attachment-dialog-form'
						type='submit'
						variant='contained'
					>
						<Trans i18nKey='ok'/>
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={!!tooLargeFiles.length} onClose={handleProblemClose} id='large-files-dialog'>
				<DialogTitle id='large-files-title'>Problem!</DialogTitle>
				<DialogContent id='large-files-description'>
					<Trans i18nKey='too-large-files'/>&nbsp;
					<Trans
						i18nKey='max-upload'
						values={{'size': countUnit(ServerManager.instance.getMaxUpload() || 0)}}
					/>
					<ul>
						{tooLargeFiles.map((f: File, index: number): JSX.Element => <li key={index}>{f.name}</li>)}
					</ul>
				</DialogContent>
				<DialogActions>
					<Button
						id='button-problem-close'
						onClick={handleProblemClose}
						color='primary'
						variant='outlined'
					>
						<Trans i18nKey='ok'/>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
