import {Attachment, DraftAttachment} from '../../entity/Attachment';
import {AxiosRequestConfig} from 'axios';
import {EntityRepository} from './EntityRepository';
import {ServerMediator} from '../ServerMediator';

export class AttachmentsRepository extends EntityRepository<string, Attachment, DraftAttachment, FormData> {
	constructor() {
		super('attachments');
	}

	getAll(axiosConfig?: AxiosRequestConfig): Promise<Attachment[]> {
		return ServerMediator.requestWithToken<Attachment[]>('GET', this.createRoute(), axiosConfig);
	}

	get(uuid: string, axiosConfig?: AxiosRequestConfig): Promise<Attachment> {
		return ServerMediator.requestWithToken<Attachment>('GET', this.createRoute(uuid), axiosConfig);
	}

	create(formData: FormData, axiosConfig: AxiosRequestConfig): Promise<string> {
		return ServerMediator.requestWithToken<string>('POST', this.createRoute(), axiosConfig, formData);
	}

	update(uuid: string, draftAttachment: DraftAttachment, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('PATCH', this.createRoute(uuid), axiosConfig, draftAttachment);
	}

	remove(attachment: Attachment, axiosConfig?: AxiosRequestConfig): Promise<void> {
		return ServerMediator.requestWithToken('DELETE', this.createRoute(attachment.uuid), axiosConfig);
	}
}
