export type PassphraseStrength =
	'WEAKER'
	| 'WEAK'
	| 'MODERATE'
	| 'STRONG'
	| 'STRONGER';

export type FulfilType =
	'NONE'
	| 'PARTIAL'
	| 'FULL';

export interface PassphraseData {
	strength: PassphraseStrength;
	lowerCase: FulfilType;
	upperCase: FulfilType;
	digits: FulfilType;
	specialChars: FulfilType;
}

interface AnalyzedPassphrase {
	lower: number;
	upper: number;
	digits: number;
	special: number;
	groups: number;
}

export function getPassphraseData(passphrase: string): PassphraseData | undefined {
	if (passphrase.length < 8) {
		return undefined;
	}

	return {
		strength: countStrength(passphrase),
		lowerCase: countChars(passphrase, /[^a-z]/g, 3),
		upperCase: countChars(passphrase, /[^A-Z]/g, 3),
		digits: countChars(passphrase, /[^0-9]/g, 3),
		specialChars: countChars(passphrase, /[a-zA-Z0-9]/g, 3)
	};
}

function countChars(passphrase: string, regex: RegExp, limit: number): FulfilType {
	const count: number = passphrase.replace(regex, '').length;
	if (count === 0) {
		return 'NONE';
	}
	if (count <= limit) {
		return 'PARTIAL';
	}
	return 'FULL';
}

function countStrength(passphrase: string): PassphraseStrength {
	const {lower, upper, digits, special, groups}: AnalyzedPassphrase = analyzePassphrase(passphrase);

	if (groups <= 2) {
		return 'WEAKER';
	}

	if (passphrase.length <= 10) {
		return 'WEAK';
	}

	if (passphrase.length > 12 && groups === 4 && lower >= 2 && upper >= 2 && special >= 2 && digits >= 3) {
		return 'STRONGER';
	}

	if (groups === 4 || passphrase.length > 12) {
		return 'STRONG';
	}

	return 'MODERATE';
}

function analyzePassphrase(password: string): AnalyzedPassphrase {
	const lower: number = password.match(/\p{Ll}/gu)?.length || 0;
	const upper: number = password.match(/\p{Lu}/gu)?.length || 0;
	const digits: number = password.match(/[0-9]/gu)?.length || 0;
	const special: number = password.match(/[^\p{L}0-9]/gu)?.length || 0;

	const groups: number = +!!lower + +!!upper + +!!digits + +!!special;

	return {lower, upper, digits, special, groups};
}
