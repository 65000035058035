import React from 'react';
import {Project} from '../entity/Project';
import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Tooltip
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {StatusChip} from '../components/StatusChip';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {findStatus} from '../entity/ProjectStatus';
import {Trans, useTranslation} from 'react-i18next';
import h2p from 'html2plaintext';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((theme: Theme): StyleRules =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		columnId: {
			width: '50px',
			maxWidth: '50px'
		},
		columnName: {
			width: '250px',
			maxWidth: '250px'
		},
		columnDescription: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			maxWidth: '500px'
		},
		columnStatus: {
			width: '150px',
			maxWidth: '150px',
			textAlign: 'center'
		},
		columnButtons: {
			width: '100px',
			maxWidth: '100px'
		}
	})
);

export interface ProjectsPageProps {
	projects: Project[];
	onProjectRemove?: (project: Project) => Promise<void>;
}

export function ProjectsPage(props: ProjectsPageProps): JSX.Element {
	const [deleting, setDeleting] = React.useState<Project | undefined>(undefined);
	const [deletingName, setDeletingName] = React.useState<string>('');

	const style: ClassNameMap = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleDeleteCancel(): void {
		setDeleting(undefined);
		setDeletingName('');
	}

	function handleDelete(): void {
		if (deleting) {
			props.onProjectRemove?.(deleting)
				.then((): string | number => enqueueSnackbar(t('project-removed'), {variant: 'success'}))
				.finally((): void => setDeleting(undefined))
				.finally((): void => setDeletingName(''));
		}
	}

	function renderProjectRow(project: Project): JSX.Element {
		return <TableRow key={project.id}>
			<TableCell component='th' scope='row' className={style.columnId}>{project.id}</TableCell>
			<TableCell className={style.columnName}>{project.name}</TableCell>
			<TableCell className={style.columnDescription}>{h2p(project.description)}</TableCell>
			<TableCell className={style.columnStatus}>
				<StatusChip status={findStatus(project.status)}/>
			</TableCell>
			<TableCell className={style.columnButtons} align='right'>
				<Link to={`/project/${project.id}`}>
					<Tooltip title={t<string>('details')} arrow>
						<IconButton
							id='button-view'
							size='small'
							aria-label='view'
						>
							<VisibilityOutlinedIcon/>
						</IconButton>
					</Tooltip>
				</Link>
				<Tooltip title={t<string>('delete')} arrow>
					<IconButton
						id='button-delete'
						size='small'
						aria-label='delete'
						onClick={(): void => setDeleting(project)}
					>
						<DeleteIcon/>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>;
	}

	return <div className={style.content}>
		<TableContainer component={Paper}>
			<Table aria-label='Projects table' size='small'>
				<TableHead>
					<TableRow>
						<TableCell className={style.columnId}>ID</TableCell>
						<TableCell className={style.columnName}><Trans i18nKey='name'/></TableCell>
						<TableCell><Trans i18nKey='description'/></TableCell>
						<TableCell className={style.columnStatus}><Trans i18nKey='status'/></TableCell>
						<TableCell className={style.columnButtons}/>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.projects.map(renderProjectRow)}
				</TableBody>
			</Table>
		</TableContainer>
		<Link to='/project/create'>
			<Tooltip title={t<string>('create-new-project')}>
				<Fab aria-label='add' style={{position: 'fixed', right: 30, bottom: 30}}>
					<AddIcon/>
				</Fab>
			</Tooltip>
		</Link>
		<Dialog
			open={!!deleting}
			onClose={handleDeleteCancel}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					You are about to delete the <code>{deleting?.name}</code> project.
					To confirm the deletion, please provide the following project name and
					id <code>{`${deleting?.name}-${deleting?.id}`}</code> below:
				</DialogContentText>
				<TextField
					autoComplete='off'
					id='project-name'
					label='Project name'
					variant='outlined'
					fullWidth
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDeletingName(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button id='delete-cancel' onClick={handleDeleteCancel} variant='outlined'>
					<Trans i18nKey='cancel'/>
				</Button>
				<Button
					id='button-delete-project'
					onClick={handleDelete}
					variant='contained'
					disabled={deletingName !== `${deleting?.name}-${deleting?.id}`}
				>
					<Trans i18nKey='delete'/>
				</Button>
			</DialogActions>
		</Dialog>
	</div>;
}

export default ProjectsPage;
