import React from 'react';
import {createStyles, FormControl, InputLabel, MenuItem, Select, Theme} from '@material-ui/core';
import {User} from '../../entity/User';
import {ClassNameMap, StyleRules} from '@material-ui/styles/withStyles';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles: (theme?: Theme) => ClassNameMap = makeStyles((): StyleRules =>
	createStyles({
		userName: {
			width: '125px',
			minWidth: '125px'
		},
		userEmail: {
			fontSize: '12px',
			color: '#7f7f7f'
		},
		userNameSmall: {
			fontSize: '14px'
		}
	})
);

export interface UserPickerProps {
	name: string;
	users: User[];
	onChange?: (userId: number) => void;
	selectedUser: number;
	id?: string;
	viewSize?: 'normal' | 'small';
}

export function UserPicker(props: UserPickerProps): JSX.Element {
	const style: ClassNameMap = useStyles();

	function renderShortValue(value: unknown): JSX.Element {
		const foundUser: User | undefined = props.users.find((user: User): boolean => user.id === value as number);
		return <span>
			<div className={clsx(style.userName, {[style.userNameSmall]: props.viewSize === 'small'})}>
				{foundUser?.displayName}
			</div>
		</span>;
	}

	function renderValue(value: unknown): JSX.Element {
		const foundUser: User | undefined = props.users.find((user: User): boolean => user.id === value as number);
		return <span>
			<div className={style.userName}>{foundUser?.displayName}</div>
			<div className={style.userEmail}>{foundUser?.email}</div>
		</span>;
	}

	return (
		<div id={props.id}>
			<FormControl variant='outlined' fullWidth>
				<InputLabel id='user-label'>{props.name}</InputLabel>
				<Select
					labelId='user-label'
					id='user'
					value={props.selectedUser}
					SelectDisplayProps={props.viewSize === 'normal' ? undefined : {
						style: {
							paddingTop: '14px',
							paddingBottom: '14px'
						}
					}}
					onChange={(event: React.ChangeEvent<{ value: unknown }>): void => props.onChange?.(event.target.value as number)}
					label={props.name}
					renderValue={props.viewSize === 'small' ? renderShortValue : renderValue}
				>
					{props.users.map((user: User): JSX.Element =>
						<MenuItem value={user.id} key={user.id}>
							{renderValue(user.id)}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
}
