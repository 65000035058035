import React from 'react';
import {Button, Grid, Paper, TextField, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {DraftUser, User} from '../../entity/User';
import {useSnackbar} from 'notistack';

export interface UserSettingsPage {
	user: User;
	onUserUpdate?: (user: User, draftUser: Partial<DraftUser>) => Promise<void>;
}

export function UserSettingsPage(props: UserSettingsPage): JSX.Element {
	const [email, setEmail] = React.useState<string>(props.user.email);
	const [username, setUsername] = React.useState<string>(props.user.username);
	const [displayName, setDisplayName] = React.useState<string>(props.user.displayName);
	const [emailError, setEmailError] = React.useState<boolean>(false);
	const [usernameError, setUsernameError] = React.useState<boolean>(false);
	const [displayNameError, setDisplayNameError] = React.useState<boolean>(false);

	const EMAIL_REGEX: RegExp = /^[a-z0-9._+]+@[a-z0-9._+]+\.[a-z0-9]+$/i;
	const USERNAME_REGEX: RegExp = /^[a-z0-9]+$/i;
	const DISPLAY_NAME_REGEX: RegExp = /^[0-9\s\p{L}-]+$/ui;

	const {enqueueSnackbar} = useSnackbar();
	const {t} = useTranslation();

	function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setEmail(event.target.value);
		setEmailError(false);
	}

	function handleUsernameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setUsername(event.target.value);
		setUsernameError(false);
	}

	function handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setDisplayName(event.target.value);
		setDisplayNameError(false);
	}

	function handleUpdateConfirm(): void {
		const usernameCorrect: boolean = USERNAME_REGEX.test(username);
		const emailCorrect: boolean = EMAIL_REGEX.test(email);
		const displayNameCorrect: boolean = DISPLAY_NAME_REGEX.test(displayName);
		setUsernameError(!usernameCorrect);
		setEmailError(!emailCorrect);
		setDisplayNameError(!displayNameCorrect);
		if (usernameCorrect && emailCorrect && displayNameCorrect && props.user) {
			props.onUserUpdate?.(props.user, {username, email, displayName})
				.then((): string | number => enqueueSnackbar(t('settings-updated'), {variant: 'success'}))
				.catch((): string | number => enqueueSnackbar(t('unexpected-error'), {variant: 'error'}));
		}
	}

	function dataChanged(): boolean {
		return props.user.username !== username
			|| props.user.displayName !== displayName
			|| props.user.email !== email;
	}

	return <div>
		<Grid
			container
			direction='column'
			justify='flex-start'
			spacing={4}
			component={Paper}
		>
			<Grid item>
				<Typography variant='h5'><Trans i18nKey='user-settings'/></Typography>
			</Grid>
			<Grid item>
				<TextField
					id='settings-username'
					label={t('username')}
					variant='outlined'
					error={usernameError}
					fullWidth
					disabled={!username}
					defaultValue={props.user.username}
					onChange={handleUsernameChange}
				/>
			</Grid>
			<Grid item>
				<TextField
					id='settings-email'
					label={t('email')}
					variant='outlined'
					error={emailError}
					fullWidth
					disabled={!username}
					defaultValue={props.user.email}
					onChange={handleEmailChange}
				/>
			</Grid>
			<Grid item>
				<TextField
					className='form-element'
					id='settings-display-name'
					label={t('display-name')}
					variant='outlined'
					fullWidth
					error={displayNameError}
					disabled={!username}
					defaultValue={props.user.displayName}
					onChange={handleDisplayNameChange}
				/>
			</Grid>
			<Grid
				item
				container
				direction='row'
				justify='center'
				alignItems='flex-start'
				spacing={2}
			>
				<Grid item xs={6}/>
				<Grid item xs={6}>
					<Button
						id='button-edit-settings-confirm'
						fullWidth
						variant='contained'
						onClick={handleUpdateConfirm}
						disabled={!dataChanged()}
					>
						<Trans i18nKey='accept'/>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</div>;
}
